define("open-event-frontend/models/event-statistics-general", ["exports", "ember-data/attr", "open-event-frontend/models/base", "ember-data/relationships"], function (_exports, _attr, _base, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    speakers: (0, _attr.default)('number'),
    sessions: (0, _attr.default)('number'),
    sessionsPending: (0, _attr.default)('number'),
    sponsors: (0, _attr.default)('number'),
    sessionsSubmitted: (0, _attr.default)('number'),
    sessionsRejected: (0, _attr.default)('number'),
    sessionsConfirmed: (0, _attr.default)('number'),
    sessionsWithdrawn: (0, _attr.default)('number'),
    sessionsCanceled: (0, _attr.default)('number'),
    identifier: (0, _attr.default)('string'),
    sessionsAccepted: (0, _attr.default)('number'),
    sessionsDraft: (0, _attr.default)('number'),
    speakersAccepted: (0, _attr.default)('number'),
    speakersConfirmed: (0, _attr.default)('number'),
    speakersPending: (0, _attr.default)('number'),
    speakersRejected: (0, _attr.default)('number'),
    speakersWithdrawn: (0, _attr.default)('number'),
    speakersCanceled: (0, _attr.default)('number'),
    event: (0, _relationships.belongsTo)('event')
  });

  _exports.default = _default;
});
define("open-event-frontend/models/tax", ["exports", "ember-data/attr", "open-event-frontend/models/base", "ember-data/relationships"], function (_exports, _attr, _base, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    name: (0, _attr.default)('string'),
    taxId: (0, _attr.default)('string'),
    rate: (0, _attr.default)('number'),
    shouldSendInvoice: (0, _attr.default)('boolean'),
    country: (0, _attr.default)('string'),
    registeredCompany: (0, _attr.default)('string'),
    address: (0, _attr.default)('string'),
    city: (0, _attr.default)('string'),
    state: (0, _attr.default)('string'),
    zip: (0, _attr.default)('string'),
    invoiceFooter: (0, _attr.default)('string'),
    isInvoiceSent: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    isTaxIncludedInPrice: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    event: (0, _relationships.belongsTo)('event')
  });

  _exports.default = _default;
});
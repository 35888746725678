define("open-event-frontend/templates/admin/content/system-images", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OGmjUH2u",
    "block": "{\"symbols\":[\"topic\"],\"statements\":[[10,\"div\"],[14,0,\"ui stackable grid\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"three wide column\"],[12],[2,\"\\n      \"],[8,\"tabbed-navigation\",[],[[\"@isVertical\"],[true]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,1],[[30,[36,0],[[30,[36,0],[[32,0,[\"model\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[8,\"link-to\",[[24,0,\"link item\"]],[[\"@route\",\"@model\"],[\"admin.content.system-images.list\",[32,1,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[1,[32,1,[\"name\"]]],[2,\"\\n          \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"      \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"thirteen wide column\"],[12],[2,\"\\n      \"],[1,[30,[36,3],[[30,[36,2],null,null]],null]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"-track-array\",\"each\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/admin/content/system-images.hbs"
    }
  });

  _exports.default = _default;
});
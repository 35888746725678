define("open-event-frontend/helpers/confirm", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Helper to execute an action after a confirmation dialog
   */
  var _default = Ember.Helper.extend({
    confirm: Ember.inject.service(),
    compute: function compute(params) {
      var _this = this;

      return function () {
        if (params.length >= 6) {
          _this.confirm.prompt('Are You Sure?', {
            'denyText': params[2],
            'approveText': params[3],
            'denyColor': params[4],
            'approveColor': params[5],
            'extra': params[0]
          }).then(function () {
            params[1]();
          });
        } else if (params.length >= 2) {
          _this.confirm.prompt(params[0]).then(function () {
            params[1]();
          });
        } else {
          _this.confirm.prompt().then(function () {
            params[0]();
          });
        }
      };
    }
  });

  _exports.default = _default;
});
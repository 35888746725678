define("open-event-frontend/models/custom-form", ["exports", "ember-data/attr", "open-event-frontend/models/base", "ember-data/relationships"], function (_exports, _attr, _base, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.SESSION_FORM_ORDER = _exports.SESSION_FORM_FIELDS = _exports.SPEAKER_FORM_ORDER = _exports.SPEAKER_FORM_FIELDS = void 0;
  var SPEAKER_FORM_FIELDS = {
    name: 'Name',
    email: 'Email',
    position: 'Position',
    organisation: 'Organisation',
    address: 'Address',
    city: 'City',
    country: 'Country',
    mobile: 'Mobile',
    gender: 'Gender',
    shortBiography: 'Short Biography',
    longBiography: 'Long Biography',
    speakingExperience: 'Speaking Experience',
    photoUrl: 'Photo',
    website: 'Website',
    github: 'GitHub',
    facebook: 'Facebook',
    twitter: 'Twitter',
    instagram: 'Instagram',
    linkedin: 'Linkedin',
    heardFrom: 'Heard From',
    sponsorshipRequired: 'Sponsorship Required'
  };
  _exports.SPEAKER_FORM_FIELDS = SPEAKER_FORM_FIELDS;
  var SPEAKER_FORM_ORDER = Object.keys(SPEAKER_FORM_FIELDS);
  _exports.SPEAKER_FORM_ORDER = SPEAKER_FORM_ORDER;
  var SESSION_FORM_FIELDS = {
    title: 'Title',
    subtitle: 'Subtitle',
    track: 'Track',
    sessionType: 'Session Type',
    shortAbstract: 'Short Abstract',
    longAbstract: 'Long Abstract',
    language: 'Language',
    level: 'Level',
    slidesUrl: 'Slides',
    videoUrl: 'Video',
    audioUrl: 'Audio',
    comments: 'Comments'
  };
  _exports.SESSION_FORM_FIELDS = SESSION_FORM_FIELDS;
  var SESSION_FORM_ORDER = Object.keys(SESSION_FORM_FIELDS);
  _exports.SESSION_FORM_ORDER = SESSION_FORM_ORDER;

  var _default = _base.default.extend({
    fieldIdentifier: (0, _attr.default)('string'),
    form: (0, _attr.default)('string'),
    type: (0, _attr.default)('string', {
      defaultValue: 'text'
    }),
    name: (0, _attr.default)('string'),
    isRequired: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    isIncluded: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    isFixed: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    isComplex: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    description: (0, _attr.default)('string', {
      defaultValue: 'text'
    }),
    event: (0, _relationships.belongsTo)('event'),
    customFormOptions: (0, _relationships.hasMany)('customFormOptions'),
    session: SESSION_FORM_FIELDS,
    speaker: SPEAKER_FORM_FIELDS,
    attendee: {
      firstname: 'First Name',
      lastname: 'Last Name',
      email: 'Email',
      address: 'Address',
      city: 'City',
      state: 'State',
      country: 'Country',
      jobTitle: 'Job Title',
      phone: 'Phone',
      taxBusinessInfo: 'Tax Business Info',
      billingAddress: 'Billing Address',
      homeAddress: 'Home Address',
      shippingAddress: 'Shipping Address',
      company: 'Company',
      workAddress: 'Work Address',
      workPhone: 'Work Phone',
      website: 'Website',
      blog: 'Blog',
      twitter: 'Twitter',
      facebook: 'Facebook',
      github: 'GitHub',
      gender: 'Gender',
      ageGroup: 'Age Group'
    },
    ready: function ready() {
      if (!this.name) {
        this.name = this[this.form][this.fieldIdentifier];
      }
    },
    identifierPath: Ember.computed('isComplex', function () {
      return !this.isComplex ? this.fieldIdentifier : 'complexFieldValues.' + this.fieldIdentifier;
    }),
    isLongText: Ember.computed('type', function () {
      return this.type === 'text' && ['shortBiography', 'longBiography', 'longAbstract', 'shortAbstract', 'comments', 'speakingExperience'].includes(this.fieldIdentifier);
    }),
    isIncludedObserver: Ember.observer('isIncluded', function () {
      if (!this.isIncluded && this.isRequired) {
        this.set('isRequired', false);
      }
    }),
    isUrlField: Ember.computed('type', 'fieldIdentifier', function () {
      return this.type === 'text' && ['website', 'twitter', 'github', 'facebook', 'linkedin', 'slidesUrl', 'instagram', 'videoUrl', 'audioUrl'].includes(this.fieldIdentifier);
    }),
    isRequiredObserver: Ember.observer('isRequired', function () {
      if (!this.isIncluded && this.isRequired) {
        this.set('isIncluded', true);
      }
    })
  });

  _exports.default = _default;
});
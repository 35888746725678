define("open-event-frontend/helpers/is-input-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.isInputField = void 0;

  var isInputField = function isInputField(params) {
    return params[0] === 'text' || params[0] === 'number' || params[0] === 'email';
  };

  _exports.isInputField = isInputField;

  var _default = Ember.Helper.helper(isInputField);

  _exports.default = _default;
});
define("open-event-frontend/helpers/session-color", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.sessionColor = sessionColor;
  _exports.default = void 0;

  function sessionColor(params) {
    switch (params[0]) {
      case 'accepted':
        return 'yellow';

      case 'pending':
        return 'yellow';

      case 'confirmed':
        return 'green';

      default:
        return 'red';
    }
  }

  var _default = Ember.Helper.helper(sessionColor);

  _exports.default = _default;
});
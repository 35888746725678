define("open-event-frontend/components/forms/admin/settings/analytics-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      submit: function submit() {
        this.sendAction('save');
      }
    }
  });

  _exports.default = _default;
});
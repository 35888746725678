define("open-event-frontend/templates/my-sessions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ftZgPlRs",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,5],[[30,[36,4],[[30,[36,3],[[32,0,[\"session\",\"currentRouteName\"]],\"my-sessions.view\"],null]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[8,\"unverified-user-message\",[],[[],[]],null],[2,\"\\n  \"],[10,\"h1\"],[14,0,\"ui header\"],[12],[1,[30,[36,2],[\"My Sessions\"],null]],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"ui grid\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"sixteen wide column\"],[12],[2,\"\\n        \"],[8,\"tabbed-navigation\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n          \"],[8,\"link-to\",[[24,0,\"item\"]],[[\"@route\",\"@model\"],[\"my-sessions.list\",\"upcoming\"]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[1,[30,[36,2],[\"Upcoming Sessions\"],null]],[2,\"\\n          \"]],\"parameters\":[]}]]],[2,\"\\n          \"],[8,\"link-to\",[[24,0,\"item\"]],[[\"@route\",\"@model\"],[\"my-sessions.list\",\"past\"]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[1,[30,[36,2],[\"Past Sessions\"],null]],[2,\"\\n          \"]],\"parameters\":[]}]]],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[1,[30,[36,1],[[30,[36,0],null,null]],null]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[1,[30,[36,1],[[30,[36,0],null,null]],null]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"-outlet\",\"component\",\"t\",\"not-includes\",\"and\",\"if\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/my-sessions.hbs"
    }
  });

  _exports.default = _default;
});
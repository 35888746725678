define("open-event-frontend/templates/reset-password", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vGN+OwQc",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"ui one column container stackable doubling centered grid\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"column\"],[12],[2,\"\\n    \"],[8,\"forms/reset-password-form\",[],[[\"@token\"],[[32,0,[\"token\"]]]],null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/reset-password.hbs"
    }
  });

  _exports.default = _default;
});
define("open-event-frontend/utils/dictionary/age-groups", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ageGroups = void 0;
  var ageGroups = [{
    age: '19 or less'
  }, {
    age: '20 to 29'
  }, {
    age: '30 to 39'
  }, {
    age: '40 to 49'
  }, {
    age: '50 or above'
  }];
  _exports.ageGroups = ageGroups;
});
define("open-event-frontend/templates/components/forms/admin/settings/microservices-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "a9JKs+sY",
    "block": "{\"symbols\":[],\"statements\":[[11,\"form\"],[24,0,\"ui form\"],[4,[38,0],[[32,0],\"submit\"],[[\"on\"],[\"submit\"]]],[12],[2,\"\\n  \"],[10,\"h3\"],[14,0,\"ui header\"],[12],[2,\"\\n    \"],[1,[30,[36,1],[\"Android App Generator\"],null]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"field\"],[12],[2,\"\\n    \"],[10,\"label\"],[12],[2,\"\\n      \"],[1,[30,[36,1],[\"API Server\"],null]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[8,\"widgets/forms/link-field\",[],[[\"@inputId\",\"@value\",\"@onChange\"],[\"android_app\",[32,0,[\"settings\",\"androidAppUrl\"]],[30,[36,0],[[32,0],[30,[36,2],[[32,0,[\"settings\",\"androidAppUrl\"]]],null]],null]]],null],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"h3\"],[14,0,\"ui header\"],[12],[2,\"\\n    \"],[1,[30,[36,1],[\"Web App Generator\"],null]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"field\"],[12],[2,\"\\n    \"],[10,\"label\"],[12],[2,\"\\n      \"],[1,[30,[36,1],[\"API Server\"],null]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[8,\"widgets/forms/link-field\",[],[[\"@inputId\",\"@value\",\"@onChange\"],[\"web_app\",[32,0,[\"settings\",\"webAppUrl\"]],[30,[36,0],[[32,0],[30,[36,2],[[32,0,[\"settings\",\"webAppUrl\"]]],null]],null]]],null],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"button\"],[14,0,\"ui teal wide button\"],[14,4,\"submit\"],[12],[2,\"\\n    \"],[1,[30,[36,1],[\"Save\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"t\",\"mut\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/forms/admin/settings/microservices-form.hbs"
    }
  });

  _exports.default = _default;
});
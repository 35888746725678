define("open-event-frontend/mixins/ember-table-controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    queryParams: ['page', 'per_page', 'search', 'sort_dir', 'sort_by'],
    page: 1,
    per_page: 10,
    search: null,
    sort_dir: null,
    sort_by: null,
    sorts: [],

    /*
      Refreshes the current model, with latest data from API server without reloading
      the entire page.
      Requires correct context.
      Usage example inside a controller:
      this.refreshModel.bind(this)()
     */
    refreshModel: function refreshModel() {
      Ember.getOwner(this).lookup("route:".concat(this.router.currentRoute.name)).refresh();
    }
  });

  _exports.default = _default;
});
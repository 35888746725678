define("open-event-frontend/templates/components/ui-table/cell/cell-input-number", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ysDGV/M6",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"inline field\"],[12],[2,\"\\n  \"],[8,\"input\",[],[[\"@value\",\"@type\",\"@min\"],[[32,0,[\"record\",\"quantity\"]],\"number\",\"0\"]],null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/ui-table/cell/cell-input-number.hbs"
    }
  });

  _exports.default = _default;
});
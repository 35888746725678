define("open-event-frontend/templates/components/modals/event-delete-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jIDbwW4A",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"header\"],[12],[2,\"\\n  \"],[1,[30,[36,0],[\"Are you sure you would like to delete this event?\"],null]],[2,\"\\n  \"],[10,\"div\"],[14,0,\"muted small text\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"Deleting the event will delete all the data associated with it.\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"content\"],[12],[2,\"\\n  \"],[11,\"form\"],[16,0,[31,[\"ui \",[30,[36,1],[[32,0,[\"isLoading\"]],\"loading\"],null],\" form\"]]],[24,1,\"delete-form\"],[24,\"autocomplete\",\"off\"],[4,[38,2],[[32,0],[32,0,[\"deleteEvent\"]]],[[\"on\",\"preventDefault\"],[\"submit\",true]]],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"field\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"label\"],[12],[2,\"\\n        \"],[1,[30,[36,0],[\"Please enter the full name of the event to continue\"],null]],[2,\"\\n      \"],[13],[2,\"\\n      \"],[8,\"input\",[[16,\"required\",true]],[[\"@type\",\"@name\",\"@value\"],[\"text\",\"confirm_name\",[32,0,[\"confirmName\"]]]],null],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"actions\"],[12],[2,\"\\n  \"],[11,\"button\"],[24,0,\"ui black button\"],[24,4,\"button\"],[4,[38,2],[[32,0],\"close\"],null],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"Cancel\"],null]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"button\"],[14,\"form\",\"delete-form\"],[14,0,\"ui red button\"],[15,\"disabled\",[32,0,[\"isNameDifferent\"]]],[14,4,\"submit\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"Delete Event\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"if\",\"action\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/modals/event-delete-modal.hbs"
    }
  });

  _exports.default = _default;
});
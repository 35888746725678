define("open-event-frontend/templates/components/public/speaker-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9FUi18h0",
    "block": "{\"symbols\":[\"speaker\"],\"statements\":[[10,\"h3\"],[14,0,\"ui header\"],[12],[1,[30,[36,0],[\"Speakers\"],null]],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"speaker-list ui grid\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,0,[\"speakers\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"public/speaker-item\",[],[[\"@speaker\"],[[32,1]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/public/speaker-list.hbs"
    }
  });

  _exports.default = _default;
});
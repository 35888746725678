define("open-event-frontend/adapters/admin-sales-invoice", ["exports", "open-event-frontend/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    buildURL: function buildURL(modelName, id, snapshot, requestType, query) {
      var url = this._super(modelName, id, snapshot, requestType, query);

      url = url.replace('admin-sales-by-invoice', 'admin/sales/invoice');
      return url;
    }
  });

  _exports.default = _default;
});
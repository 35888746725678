define("open-event-frontend/templates/components/modals/admin/content/new-event-topic-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DD8OjrM0",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"header\"],[12],[2,\"\\n  \"],[1,[30,[36,0],[\"Add New Event Topic\"],null]],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"content\"],[12],[2,\"\\n  \"],[11,\"form\"],[16,0,[31,[\"ui \",[30,[36,1],[[32,0,[\"isLoading\"]],\"loading\"],null],\" form\"]]],[24,1,\"add-event-topic-form\"],[24,\"autocomplete\",\"off\"],[4,[38,2],[[32,0],\"addEventProperty\",[32,0,[\"eventTopic\"]]],[[\"on\",\"preventDefault\"],[\"submit\",true]]],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"field\"],[12],[2,\"\\n      \"],[10,\"label\"],[14,0,\"required\"],[12],[2,\"\\n        \"],[1,[30,[36,0],[\"New Event Topic\"],null]],[2,\"\\n      \"],[13],[2,\"\\n      \"],[8,\"input\",[[16,\"placeholder\",[30,[36,0],[\"Enter New Event Topic\"],null]]],[[\"@type\",\"@name\",\"@value\"],[\"text\",\"topic_name\",[34,3]]],null],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"actions\"],[12],[2,\"\\n  \"],[11,\"button\"],[24,0,\"ui black button\"],[24,4,\"button\"],[4,[38,2],[[32,0],\"close\"],null],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"Cancel\"],null]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"button\"],[14,\"form\",\"add-event-topic-form\"],[14,0,\"ui green right labeled icon button\"],[14,4,\"submit\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"Add Event Topic\"],null]],[2,\"\\n    \"],[10,\"i\"],[14,0,\"checkmark icon\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"if\",\"action\",\"name\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/modals/admin/content/new-event-topic-modal.hbs"
    }
  });

  _exports.default = _default;
});
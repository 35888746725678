define("open-event-frontend/adapters/user", ["exports", "open-event-frontend/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    buildURL: function buildURL(modelName, id, snapshot, requestType, query) {
      var url = this._super(modelName, id, snapshot, requestType, query);

      if (requestType === 'updateRecord' && snapshot.adapterOptions && snapshot.adapterOptions.getTrashed) {
        url = "".concat(url, "?get_trashed=true");
      }

      return url;
    }
  });

  _exports.default = _default;
});
define("open-event-frontend/templates/components/ui-table/cell/events/view/videoroom/cell-stream-url", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ALGeFobY",
    "block": "{\"symbols\":[\"@record\",\"@extraRecords\"],\"statements\":[[6,[37,2],[[32,1]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"a\"],[14,0,\"mt-2\"],[15,6,[31,[[30,[36,0],[\"public.stream.view\",[32,2,[\"event\",\"identifier\"]],[32,1,[\"slugName\"]],[32,1]],null]]]],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener\"],[12],[1,[30,[36,1],[\"Open\"],null]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"href-to\",\"t\",\"if\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/ui-table/cell/events/view/videoroom/cell-stream-url.hbs"
    }
  });

  _exports.default = _default;
});
define("open-event-frontend/utils/computed-helpers", ["exports", "moment", "open-event-frontend/utils/dictionary/date-time"], function (_exports, _moment, _dateTime) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.computedDateTimeSplit = void 0;

  /**
   * Get, set split date time from/to a Full datetime object
   * @param property The full date time object
   * @param segmentFormat The part of the date to be returned. (time/date or a custom format)
   * @param endProperty Optional end field name for date or time.
   * @returns {*}
   */
  var computedDateTimeSplit = function computedDateTimeSplit(property, segmentFormat, endProperty) {
    return Ember.computed(property, {
      get: function get() {
        var momentDate = (0, _moment.default)(this.get(property));

        if (this.constructor.modelName === 'event') {
          momentDate = momentDate.tz(this.timezone);
        }

        return momentDate.format(getFormat(segmentFormat));
      },
      set: function set(key, value) {
        var newDate = (0, _moment.default)(value, getFormat(segmentFormat));

        if (this.constructor.modelName === 'event') {
          newDate = newDate.tz(this.timezone, true);
        }

        var oldDate = newDate;

        if (this.get(property)) {
          oldDate = (0, _moment.default)(this.get(property), segmentFormat === 'date' ? _dateTime.FORM_DATE_FORMAT : _dateTime.FORM_TIME_FORMAT);

          if (this.constructor.modelName === 'event') {
            oldDate = oldDate.tz(this.timezone, true);
          }
        } else {
          oldDate = newDate;
        }

        if (segmentFormat === 'time') {
          oldDate.hour(newDate.hour());
          oldDate.minute(newDate.minute());
        } else if (segmentFormat === 'date') {
          oldDate.date(newDate.date());
          oldDate.month(newDate.month());
          oldDate.year(newDate.year());
        } else {
          oldDate = newDate;
        }

        this.set(property, oldDate);

        if (endProperty) {
          if (segmentFormat === 'date' && this.get(endProperty) < oldDate) {
            this.set(endProperty, oldDate);
          }
        }

        return value;
      }
    });
  };

  _exports.computedDateTimeSplit = computedDateTimeSplit;

  function getFormat(segmentFormat) {
    return segmentFormat === 'time' ? _dateTime.FORM_TIME_FORMAT : segmentFormat === 'date' ? _dateTime.FORM_DATE_FORMAT : segmentFormat;
  }
});
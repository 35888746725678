define("open-event-frontend/templates/events/view/team/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UgotBvtj",
    "block": "{\"symbols\":[],\"statements\":[[8,\"events/view/overview/manage-roles\",[],[[\"@data\",\"@editable\"],[[32,0,[\"model\"]],true]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/events/view/team/index.hbs"
    }
  });

  _exports.default = _default;
});
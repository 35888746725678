define("open-event-frontend/components/modals/confirm-event-transfer-modal", ["exports", "open-event-frontend/mixins/form", "open-event-frontend/components/modals/modal-base"], function (_exports, _form, _modalBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _modalBase.default.extend(_form.default, {
    actions: {
      submit: function submit() {
        var _this = this;

        this.onValid(function () {
          _this.sendAction('transferEvent');
        });
      },
      close: function close() {
        if (!this.currentInvite.id) {
          this.currentInvite.unloadRecord();
        }

        this.set('isOpen', false);
      }
    },
    getValidationRules: function getValidationRules() {
      return {
        inline: true,
        delay: false,
        on: 'blur',
        fields: {
          newOwnerEmail: {
            identifier: 'user_email',
            rules: [{
              type: 'empty',
              prompt: this.l10n.t('Please enter an email for new organizer')
            }, {
              type: 'email',
              prompt: this.l10n.t('Please enter a valid email address  for new organizer')
            }]
          }
        }
      };
    }
  });

  _exports.default = _default;
});
define("open-event-frontend/templates/components/account/application-section", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wzh5RLYh",
    "block": "{\"symbols\":[],\"statements\":[[10,\"h4\"],[12],[1,[30,[36,0],[\"Connect with Facebook\"],null]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/account/application-section.hbs"
    }
  });

  _exports.default = _default;
});
define("open-event-frontend/templates/components/ui-table/header-rows-grouped", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "C8MCQBXM",
    "block": "{\"symbols\":[\"row\",\"cell\"],\"statements\":[[6,[37,1],[[30,[36,0],[[30,[36,0],[[32,0,[\"groupedHeaders\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"tr\"],[12],[2,\"\\n\"],[6,[37,1],[[30,[36,0],[[30,[36,0],[[32,1]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"th\"],[15,\"colspan\",[31,[[32,2,[\"colspan\"]]]]],[15,\"rowspan\",[31,[[32,2,[\"rowspan\"]]]]],[12],[1,[32,2,[\"title\"]]],[13],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/ui-table/header-rows-grouped.hbs"
    }
  });

  _exports.default = _default;
});
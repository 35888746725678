define("open-event-frontend/components/forms/events/view/create-access-code", ["exports", "open-event-frontend/mixins/form"], function (_exports, _form) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_form.default, {
    getValidationRules: function getValidationRules() {
      var _this = this;

      window.$.fn.form.settings.rules.checkMaxMin = function () {
        return _this.data.minQuantity <= _this.data.maxQuantity;
      };

      window.$.fn.form.settings.rules.checkMaxTotal = function () {
        return _this.data.maxQuantity <= _this.data.ticketsNumber;
      };

      return {
        inline: true,
        delay: false,
        on: 'blur',
        fields: {
          accessCode: {
            identifier: 'access_code',
            rules: [{
              type: 'empty',
              prompt: this.l10n.t('Please enter access code')
            }, {
              type: 'regExp',
              value: '^[a-zA-Z0-9_-]*$'
            }]
          },
          numberOfAccessTickets: {
            identifier: 'number_of_access_tickets',
            rules: [{
              type: 'empty',
              prompt: this.l10n.t('Please enter number of tickets')
            }, {
              type: 'number',
              prompt: this.l10n.t('Please enter proper number of tickets')
            }]
          },
          min: {
            identifier: 'min',
            optional: true,
            rules: [{
              type: 'number',
              prompt: this.l10n.t('Please enter the proper number')
            }, {
              type: 'checkMaxMin',
              prompt: this.l10n.t('Minimum value should not be greater than maximum')
            }]
          },
          max: {
            identifier: 'max',
            optional: true,
            rules: [{
              type: 'number',
              prompt: this.l10n.t('Please enter the proper number')
            }, {
              type: 'checkMaxMin',
              prompt: this.l10n.t('Maximum value should not be less than minimum')
            }, {
              type: 'checkMaxTotal',
              prompt: this.l10n.t('Maximum value should not be greater than number of tickets')
            }]
          },
          accessCodeStartDate: {
            identifier: 'start_date',
            optional: true,
            rules: [{
              type: 'date',
              prompt: this.l10n.t('Please enter the proper date')
            }]
          },
          accessCodeEndDate: {
            identifier: 'end_date',
            optional: true,
            rules: [{
              type: 'date',
              prompt: this.l10n.t('Please enter the proper date')
            }]
          }
        }
      };
    },
    accessCode: '',
    accessLink: Ember.computed('data.code', function () {
      var params = this.router._router.currentState.routerJsState.params;
      var origin = this.fastboot.isFastBoot ? "".concat(this.fastboot.request.protocol, "//").concat(this.fastboot.request.host) : location.origin;
      var link = origin + this.router.urlFor('public', params['events.view'].event_id, {
        queryParams: {
          code: this.data.code
        }
      });
      this.set('data.accessUrl', link);
      return link;
    }),
    hiddenTickets: Ember.computed.filterBy('tickets', 'isHidden', true),
    allTicketTypesChecked: Ember.computed('tickets', function () {
      if (this.hiddenTickets.length && this.data.tickets.length === this.hiddenTickets.length) {
        return true;
      }

      return false;
    }),
    isLinkSuccess: false,
    actions: {
      toggleAllSelection: function toggleAllSelection(allTicketTypesChecked) {
        this.toggleProperty('allTicketTypesChecked');
        var tickets = this.hiddenTickets;

        if (allTicketTypesChecked) {
          this.set('data.tickets', tickets.slice());
        } else {
          this.data.tickets.clear();
        }

        tickets.forEach(function (ticket) {
          ticket.set('isChecked', allTicketTypesChecked);
        });
      },
      updateTicketsSelection: function updateTicketsSelection(ticket) {
        if (!ticket.get('isChecked')) {
          this.data.tickets.pushObject(ticket);
          ticket.set('isChecked', true);

          if (this.data.tickets.length === this.hiddenTickets.length) {
            this.set('allTicketTypesChecked', true);
          }
        } else {
          this.data.tickets.removeObject(ticket);
          ticket.set('isChecked', false);
          this.set('allTicketTypesChecked', false);
        }
      },
      submit: function submit(data) {
        var _this2 = this;

        this.onValid(function () {
          _this2.sendAction('save', data);
        });
      },
      copiedText: function copiedText() {
        var _this3 = this;

        this.set('isLinkSuccess', true);
        Ember.run.later(this, function () {
          _this3.set('isLinkSuccess', false);
        }, 5000);
      }
    }
  });

  _exports.default = _default;
});
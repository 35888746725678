define("open-event-frontend/helpers/css", ["exports", "lodash-es"], function (_exports, _lodashEs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    sanitizer: Ember.inject.service(),
    compute: function compute(params, hash) {
      var style = '';
      (0, _lodashEs.forOwn)(hash, function (value, key) {
        style += "".concat(key, ": ").concat(value, ";");
      });
      return Ember.String.htmlSafe(this.sanitizer.strip(style));
    }
  });

  _exports.default = _default;
});
define("open-event-frontend/templates/admin/settings/analytics", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nQogYpRJ",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[15,0,[31,[\"ui basic \",[30,[36,0],[[32,0,[\"isLoading\"]],\"loading\",\"\"],null],\" segment\"]]],[12],[2,\"\\n  \"],[8,\"forms/admin/settings/analytics-form\",[],[[\"@save\",\"@settings\"],[\"updateSettings\",[32,0,[\"model\"]]]],null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/admin/settings/analytics.hbs"
    }
  });

  _exports.default = _default;
});
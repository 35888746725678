define("open-event-frontend/templates/my-sessions/list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "P9pOBb5q",
    "block": "{\"symbols\":[\"session\"],\"statements\":[[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"sixteen column wide\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,0,[\"model\"]]],null]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[8,\"session-card\",[],[[\"@session\"],[[32,1]]],null],[2,\"\\n      \"],[10,\"div\"],[14,0,\"ui hidden divider\"],[12],[13],[2,\"      \\n\"]],\"parameters\":[1]},{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"ui disabled header\"],[12],[1,[30,[36,0],[\"No session proposals found for the events\"],null]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[8,\"infinity-loader\",[],[[\"@infinityModel\"],[[32,0,[\"model\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[10,\"div\"],[14,0,\"ui loading very padded basic segment\"],[12],[2,\"\\n      \"],[13],[2,\"\\n      \"],[1,[32,0,[\"infintyModel\",\"reachedInfinity\"]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/my-sessions/list.hbs"
    }
  });

  _exports.default = _default;
});
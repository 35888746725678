define("open-event-frontend/helpers/general-date", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.generalDate = generalDate;
  _exports.default = void 0;
  var dateFormats = {
    'date-time-long': 'dddd, D MMMM, YYYY h:mm A',
    'date-time-tz-long': 'dddd, D MMMM, YYYY h:mm A (z)',
    'date-time-short': 'D MMM, YYYY h:mm A',
    'date-time-tz-short': 'D MMM, YYYY h:mm A (z)',
    'date-short': 'D MMM, YYYY',
    'time-tz-short': 'h:mm A (z)'
  };
  var locales12Hours = new Set(['en', 'bn', 'hi', 'id', 'ja', 'run', 'th', 'vi', 'ko']);

  function generalDate(params, _ref) {
    var tz = _ref.tz;

    var timezone = tz || _moment.default.tz.guess();

    var local = (0, _moment.default)(params[0]).tz(timezone).locale();
    var format = dateFormats[params[1]] || params[1] || 'h:mm A, MMMM Do YYYY (z)';

    if (!locales12Hours.has(local)) {
      format = format.replace(/h/g, 'H');
      format = format.replace(' A', '');
      format = format.replace(' a', '');
    }

    return (0, _moment.default)(params[0]).tz(timezone).format(format);
  }

  var _default = Ember.Helper.helper(generalDate);

  _exports.default = _default;
});
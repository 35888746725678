define("open-event-frontend/templates/public/schedule", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yPDfnFJc",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,1],[[32,0,[\"isSchedulePublished\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"d-flex\"],[12],[2,\"\\n    \"],[8,\"widgets/time-zone-picker\",[[24,0,\"mb-2 ml-auto\"]],[[\"@defaultLocal\",\"@eventTimezone\",\"@timezone\"],[[32,0,[\"model\",\"event\",\"online\"]],[32,0,[\"model\",\"event\",\"timezone\"]],[32,0,[\"timezone\"]]]],null],[2,\"\\n  \"],[13],[2,\"\\n  \"],[8,\"schedule\",[],[[\"@timezone\",\"@event\",\"@sessions\",\"@rooms\",\"@height\"],[[32,0,[\"timezone\"]],[32,0,[\"model\",\"event\"]],[32,0,[\"model\",\"sessions\"]],[32,0,[\"model\",\"microlocations\"]],\"auto\"]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"ui disabled header\"],[12],[1,[30,[36,0],[\"Schedule for the event is currently unavailable\"],null]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"if\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/public/schedule.hbs"
    }
  });

  _exports.default = _default;
});
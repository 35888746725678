define("open-event-frontend/models/admin-statistics-session", ["exports", "ember-data/attr", "open-event-frontend/models/base"], function (_exports, _attr, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    confirmed: (0, _attr.default)('number'),
    accepted: (0, _attr.default)('number'),
    submitted: (0, _attr.default)('number'),
    draft: (0, _attr.default)('number'),
    rejected: (0, _attr.default)('number'),
    pending: (0, _attr.default)('number')
  });

  _exports.default = _default;
});
define("open-event-frontend/templates/components/ui-table/cell/cell-sessions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rlvcNndq",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,1],[[32,0,[\"record\",\"sessions\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"ui list\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"item\"],[12],[1,[30,[36,0],[\"Submitted\"],null]],[2,\": \"],[1,[32,0,[\"record\",\"sessions\"]]],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"item\"],[12],[1,[30,[36,0],[\"Accepted\"],null]],[2,\": \"],[1,[32,0,[\"record\",\"sessionsAccepted\"]]],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"item\"],[12],[1,[30,[36,0],[\"Confirmed\"],null]],[2,\": \"],[1,[32,0,[\"record\",\"sessionsConfirmed\"]]],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"item\"],[12],[1,[30,[36,0],[\"Pending\"],null]],[2,\": \"],[1,[32,0,[\"record\",\"sessionsPending\"]]],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"item\"],[12],[1,[30,[36,0],[\"Rejected\"],null]],[2,\": \"],[1,[32,0,[\"record\",\"sessionsRejected\"]]],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[1,[30,[36,0],[\"No Session Information Added Yet\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"if\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/ui-table/cell/cell-sessions.hbs"
    }
  });

  _exports.default = _default;
});
define("open-event-frontend/helpers/get-properties", ["exports", "lodash-es/flatten"], function (_exports, _flatten) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getProperties = getProperties;
  _exports.default = void 0;

  /**
   * Get all the listed properties as a hash.
   *
   * @param params
   * @return {{}|(Pick<UnwrapComputedPropertyGetters<*>, *> | Pick<UnwrapComputedPropertyGetters<*>, Array>)}
   */
  function getProperties() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];

    if (params.length < 2 || !params[1]) {
      return {};
    }

    var inputParams = params.slice();
    var row = inputParams.shift();
    return Ember.getProperties(row, (0, _flatten.default)(inputParams));
  }

  var _default = Ember.Helper.helper(getProperties);

  _exports.default = _default;
});
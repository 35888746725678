define("open-event-frontend/templates/components/errors/generic-error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RGMCG+Nd",
    "block": "{\"symbols\":[],\"statements\":[[10,\"br\"],[12],[13],[2,\"\\n\"],[10,\"h1\"],[14,0,\"text muted weight-300 no bottom margin\"],[12],[1,[30,[36,0],[\"An unexpected error has occurred.\"],null]],[13],[2,\"\\n\"],[10,\"h1\"],[14,0,\"weight-300 description\"],[12],[1,[30,[36,0],[\"Oops, the page you were trying\"],null]],[10,\"br\"],[12],[13],[2,\" \"],[1,[30,[36,0],[\"to access\"],null]],[10,\"br\"],[12],[13],[2,\" \"],[1,[30,[36,0],[\"has caused an error. \"],null]],[2,\".\"],[13],[2,\"\\n\"],[10,\"h3\"],[14,0,\"weight-300\"],[12],[2,\"\\n  \"],[1,[30,[36,0],[\"This, may or may not be a server issue. We are looking into it. You may want to head back to the home page.\"],null]],[10,\"br\"],[12],[13],[2,\"\\n  \"],[1,[30,[36,0],[\"Please try visiting after some time.\"],null]],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"br\"],[12],[13],[2,\"\\n\"],[10,\"a\"],[14,0,\"ui secondary basic button\"],[15,6,[31,[[30,[36,1],[\"index\"],null]]]],[12],[1,[30,[36,0],[\"Go Back Home\"],null]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"href-to\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/errors/generic-error.hbs"
    }
  });

  _exports.default = _default;
});
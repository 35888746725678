define("open-event-frontend/templates/components/ui-table/cell/cell-title-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XvVVkSQY",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"content\"],[12],[2,\"\\n\"],[6,[37,1],[[30,[36,2],[[32,0,[\"props\",\"options\",\"subject\"]],\"emailSubject\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"a\"],[14,6,\"#\"],[14,0,\"header\"],[12],[1,[30,[36,0],[[32,0,[\"extraRecords\",\"emailSubject\"]]],null]],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,1],[[30,[36,2],[[32,0,[\"props\",\"options\",\"subject\"]],\"notificationTitle\"],null]],null,[[\"default\"],[{\"statements\":[[6,[37,1],[[32,0,[\"extraRecords\",\"notificationTitle\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"a\"],[14,6,\"#\"],[14,0,\"header\"],[12],[1,[30,[36,0],[[32,0,[\"extraRecords\",\"notificationTitle\"]]],null]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"  \"],[10,\"div\"],[14,0,\"description\"],[12],[2,\"\\n\"],[6,[37,1],[[30,[36,2],[[32,0,[\"props\",\"options\",\"message\"]],\"emailMessage\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[10,\"p\"],[12],[1,[30,[36,0],[[32,0,[\"record\"]]],null]],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,1],[[30,[36,2],[[32,0,[\"props\",\"options\",\"message\"]],\"notificationMessage\"],null]],null,[[\"default\"],[{\"statements\":[[6,[37,1],[[32,0,[\"record\"]]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"p\"],[12],[1,[30,[36,0],[[32,0,[\"record\"]]],null]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"sanitize\",\"if\",\"eq\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/ui-table/cell/cell-title-message.hbs"
    }
  });

  _exports.default = _default;
});
define("open-event-frontend/components/forms/admin/content/pages-form", ["exports", "open-event-frontend/mixins/form"], function (_exports, _form) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_form.default, {
    getValidationRules: function getValidationRules() {
      return {
        inline: true,
        delay: false,
        on: 'blur',
        fields: {
          name: {
            identifier: 'name',
            rules: [{
              type: 'empty',
              prompt: this.l10n.t('Please enter a name')
            }]
          },
          title: {
            identifier: 'title',
            rules: [{
              type: 'empty',
              prompt: this.l10n.t('Please enter a title')
            }]
          },
          url: {
            identifier: 'url',
            rules: [{
              type: 'empty',
              prompt: this.l10n.t('Please enter the path')
            }, {
              type: 'regExp',
              value: '/[^/](.*)/',
              prompt: this.l10n.t('Path should not contain leading slash.')
            }, {
              type: 'doesntContain[ ]',
              prompt: this.l10n.t('Path should not contain whitespaces.')
            }]
          },
          place: {
            identifier: 'place',
            rules: [{
              type: 'empty',
              prompt: this.l10n.t('Please select a place')
            }]
          },
          position: {
            identifier: 'position',
            rules: [{
              type: 'empty',
              prompt: this.l10n.t('Please enter a position')
            }]
          },
          language: {
            identifier: 'language',
            rules: [{
              type: 'empty',
              prompt: this.l10n.t('Please enter a language')
            }]
          }
        }
      };
    },
    actions: {
      submit: function submit(data) {
        var _this = this;

        this.onValid(function () {
          _this.sendAction('save', data);
        });
      },
      deletePage: function deletePage(data) {
        if (!this.isCreate) {
          data.destroyRecord();
          this.set('isFormOpen', false);
        }
      },
      close: function close() {
        if (this.isCreate) {
          this.set('isFormOpen', false);
        }
      }
    }
  });

  _exports.default = _default;
});
define("open-event-frontend/serializers/attendee", ["exports", "open-event-frontend/serializers/application", "open-event-frontend/mixins/custom-primary-key"], function (_exports, _application, _customPrimaryKey) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_customPrimaryKey.default, {
    serialize: function serialize(snapshot) {
      var json = this._super.apply(this, arguments);

      if (json.data.relationships.order) {
        json.data.relationships.order.data.id = snapshot._belongsToRelationships.order.__attributes.originalId;
      }

      return json;
    }
  });

  _exports.default = _default;
});
define("open-event-frontend/models/base", ["exports", "ember-data/model", "ember-data-has-many-query/mixins/model", "open-event-frontend/adapters/application"], function (_exports, _model, _model2, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend(_model2.default, {
    query: function query(propertyName, params) {
      params = (0, _application.fixFilterQuery)(params);
      return this._super(propertyName, params);
    }
  });

  _exports.default = _default;
});
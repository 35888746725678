define("open-event-frontend/templates/events/import", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fRHUAkvL",
    "block": "{\"symbols\":[],\"statements\":[[8,\"events/event-import-section\",[],[[\"@uploadFile\",\"@importStatus\",\"@importError\",\"@isImporting\",\"@file\"],[[30,[36,0],[[32,0],\"uploadFile\"],null],[32,0,[\"importStatus\"]],[32,0,[\"importError\"]],[32,0,[\"isImporting\"]],[32,0,[\"file\"]]]],null],[2,\"\\n\"],[10,\"div\"],[14,0,\"ui hidden divider\"],[12],[13],[2,\"\\n\"],[8,\"events/imports-history-section\",[],[[\"@data\",\"@columns\",\"@page\",\"@per_page\",\"@search\",\"@sort_by\",\"@sort_dir\",\"@filterOptions\"],[[32,0,[\"model\",\"importJobs\"]],[32,0,[\"columns\"]],[32,0,[\"page\"]],[32,0,[\"per_page\"]],[32,0,[\"search\"]],[32,0,[\"sort_by\"]],[32,0,[\"sort_dir\"]],[32,0,[\"filterOptions\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/events/import.hbs"
    }
  });

  _exports.default = _default;
});
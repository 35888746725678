define("open-event-frontend/templates/events/view/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SCgpIRQW",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"ui grid stackable\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"eight wide column\"],[12],[2,\"\\n      \"],[8,\"events/view/overview/event-setup-checklist\",[],[[\"@data\"],[[32,0,[\"model\"]]]],null],[2,\"\\n      \"],[8,\"events/view/overview/event-sponsors\",[],[[\"@data\",\"@columns\",\"@page\",\"@per_page\",\"@search\",\"@sort_by\",\"@sort_dir\",\"@filterOptions\"],[[32,0,[\"model\",\"sponsors\"]],[32,0,[\"columns\"]],[32,0,[\"page\"]],[32,0,[\"per_page\"]],[32,0,[\"search\"]],[32,0,[\"sort_by\"]],[32,0,[\"sort_dir\"]],[32,0,[\"filterOptions\"]]]],null],[2,\"\\n      \"],[8,\"events/view/overview/event-apps\",[],[[\"@eventId\"],[[32,0,[\"model\",\"event\",\"id\"]]]],null],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"eight wide column\"],[12],[2,\"\\n      \"],[8,\"events/view/overview/general-info\",[],[[\"@data\"],[[32,0,[\"model\"]]]],null],[2,\"\\n      \"],[8,\"events/view/overview/event-tickets\",[],[[\"@data\"],[[32,0,[\"model\"]]]],null],[2,\"\\n      \"],[8,\"events/view/overview/manage-roles\",[],[[\"@data\",\"@showLink\"],[[32,0,[\"model\"]],true]],null],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/events/view/index.hbs"
    }
  });

  _exports.default = _default;
});
define("open-event-frontend/extensions/ember-table/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PDKUfQuA",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[2,\"\\n\"],[10,\"table\"],[14,0,\"ui unstackable table\"],[12],[2,\"\\n  \"],[18,1,[[30,[36,1],null,[[\"api\",\"head\",\"body\",\"foot\"],[[32,0,[\"api\"]],[30,[36,0],[\"ember-thead\"],[[\"api\"],[[32,0,[\"api\"]]]]],[30,[36,0],[\"ember-tbody\"],[[\"api\"],[[32,0,[\"api\"]]]]],[30,[36,0],[\"ember-tfoot\"],[[\"api\"],[[32,0,[\"api\"]]]]]]]]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "open-event-frontend/extensions/ember-table/template.hbs"
    }
  });

  _exports.default = _default;
});
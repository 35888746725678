define("open-event-frontend/utils/dictionary/sessions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.SESSION_STATES = _exports.stateColorMap = void 0;
  var stateColorMap = {
    draft: 'grey',
    accepted: 'teal',
    confirmed: 'green',
    pending: 'yellow',
    rejected: 'red',
    deleted: 'red',
    withdrawn: 'black',
    canceled: 'orange'
  };
  _exports.stateColorMap = stateColorMap;
  var SESSION_STATES = ['pending', 'accepted', 'confirmed', 'rejected', 'withdrawn', 'canceled'];
  _exports.SESSION_STATES = SESSION_STATES;
});
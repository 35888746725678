define("open-event-frontend/templates/components/tables/utilities/pagination", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5QIhxlKV",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"ui small pagination menu\"],[12],[2,\"\\n  \"],[11,\"a\"],[24,6,\"#\"],[24,\"role\",\"button\"],[16,0,[31,[\"item \",[30,[36,0],[[32,0,[\"moveToPreviousPageDisabled\"]],\"disabled\"],null]]]],[4,[38,1],[[32,0],\"moveToFirstPage\"],null],[12],[2,\"\\n    \"],[10,\"i\"],[14,0,\"angle double left icon\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[11,\"a\"],[24,6,\"#\"],[24,\"role\",\"button\"],[16,0,[31,[\"item \",[30,[36,0],[[32,0,[\"moveToPreviousPageDisabled\"]],\"disabled\"],null]]]],[4,[38,1],[[32,0],\"moveToPreviousPage\"],null],[12],[2,\"\\n    \"],[10,\"i\"],[14,0,\"angle left icon\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[11,\"a\"],[24,6,\"#\"],[24,\"role\",\"button\"],[16,0,[31,[\"item \",[30,[36,0],[[32,0,[\"moveToNextPageDisabled\"]],\"disabled\"],null]]]],[4,[38,1],[[32,0],\"moveToNextPage\"],null],[12],[2,\"\\n    \"],[10,\"i\"],[14,0,\"angle right icon\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[11,\"a\"],[24,6,\"#\"],[24,\"role\",\"button\"],[16,0,[31,[\"item \",[30,[36,0],[[32,0,[\"moveToNextPageDisabled\"]],\"disabled\"],null]]]],[4,[38,1],[[32,0],\"moveToLastPage\"],null],[12],[2,\"\\n    \"],[10,\"i\"],[14,0,\"angle double right icon\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"ui right floated no padding basic segment\"],[12],[2,\"\\n  \"],[1,[30,[36,2],[\"Showing\"],null]],[2,\" \"],[1,[32,0,[\"currentRange\"]]],[2,\" \"],[1,[30,[36,2],[\"of\"],null]],[2,\"  \"],[1,[32,0,[\"totalContentLength\"]]],[2,\" \"],[1,[30,[36,2],[\"entries\"],null]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"if\",\"action\",\"t\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/tables/utilities/pagination.hbs"
    }
  });

  _exports.default = _default;
});
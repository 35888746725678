define("open-event-frontend/templates/components/ui-table/cell/events/view/tickets/discount-codes/cell-validity", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KnWnLLMF",
    "block": "{\"symbols\":[],\"statements\":[[10,\"span\"],[12],[2,\"\\n\\t\"],[1,[30,[36,0],[[32,0,[\"record\"]],\"date-time-short\"],null]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"general-date\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/ui-table/cell/events/view/tickets/discount-codes/cell-validity.hbs"
    }
  });

  _exports.default = _default;
});
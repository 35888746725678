define("open-event-frontend/components/forms/session-speaker-form", ["exports", "lodash-es", "open-event-frontend/mixins/form", "open-event-frontend/utils/validators", "open-event-frontend/utils/dictionary/demography", "open-event-frontend/utils/dictionary/languages", "open-event-frontend/utils/dictionary/genders", "open-event-frontend/utils/sort", "open-event-frontend/models/custom-form"], function (_exports, _lodashEs, _form, _validators, _demography, _languages, _genders, _sort, _customForm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_form.default, {
    newSpeakerSelected: false,
    newSessionSelected: false,
    getValidationRules: function getValidationRules() {
      var _this = this;

      var validationRules = {
        inline: true,
        delay: false,
        on: 'blur',
        fields: {
          title: {
            identifier: 'session_title_required',
            rules: [{
              type: 'empty',
              prompt: this.l10n.t('Please enter a title')
            }]
          },
          subtitle: {
            identifier: 'session_subtitle_required',
            rules: [{
              type: 'empty',
              prompt: this.l10n.t('Please enter a subtitle')
            }]
          },
          shortAbstract: {
            identifier: 'session_shortAbstract_required',
            rules: [{
              type: 'empty',
              prompt: this.l10n.t('Please enter short abstract')
            }]
          },
          longAbstract: {
            identifier: 'session_longAbstract_required',
            rules: [{
              type: 'empty',
              prompt: this.l10n.t('Please enter a long abstract')
            }]
          },
          comments: {
            identifier: 'session_comments_required',
            rules: [{
              type: 'empty',
              prompt: this.l10n.t('Please enter comments')
            }]
          },
          track: {
            identifier: 'session_track_required',
            rules: [{
              type: 'empty',
              prompt: this.l10n.t('Please select a track')
            }]
          },
          sessionType: {
            identifier: 'session_sessionType_required',
            rules: [{
              type: 'empty',
              prompt: this.l10n.t('Please select a session type')
            }]
          },
          level: {
            identifier: 'session_level_required',
            rules: [{
              type: 'empty',
              prompt: this.l10n.t('Please enter a level')
            }]
          },
          language: {
            identifier: 'session_language_required',
            rules: [{
              type: 'empty',
              prompt: this.l10n.t('Please enter a language')
            }]
          },
          slidesUrlRequired: {
            identifier: 'session_slidesUrl_required',
            rules: [{
              type: 'empty',
              prompt: this.l10n.t('Please enter a url')
            }, {
              type: 'regExp',
              value: _validators.protocolLessValidUrlPattern,
              prompt: this.l10n.t('Please enter a valid url')
            }]
          },
          slidesUrl: {
            identifier: 'session_slidesUrl',
            optional: true,
            rules: [{
              type: 'regExp',
              value: _validators.protocolLessValidUrlPattern,
              prompt: this.l10n.t('Please enter a valid url')
            }]
          },
          videoUrlRequired: {
            identifier: 'session_videoUrl_required',
            rules: [{
              type: 'empty',
              prompt: this.l10n.t('Please enter a url')
            }, {
              type: 'regExp',
              value: _validators.protocolLessValidUrlPattern,
              prompt: this.l10n.t('Please enter a valid url')
            }]
          },
          videoUrl: {
            identifier: 'session_videoUrl',
            optional: true,
            rules: [{
              type: 'regExp',
              value: _validators.protocolLessValidUrlPattern,
              prompt: this.l10n.t('Please enter a valid url')
            }]
          },
          audioUrlRequired: {
            identifier: 'session_audioUrl_required',
            rules: [{
              type: 'empty',
              prompt: this.l10n.t('Please enter a url')
            }, {
              type: 'regExp',
              value: _validators.protocolLessValidUrlPattern,
              prompt: this.l10n.t('Please enter a valid url')
            }]
          },
          audioUrl: {
            identifier: 'session_audioUrl',
            optional: true,
            rules: [{
              type: 'regExp',
              value: _validators.protocolLessValidUrlPattern,
              prompt: this.l10n.t('Please enter a valid url')
            }]
          },
          name: {
            identifier: 'speaker_name_required',
            rules: [{
              type: 'empty',
              prompt: this.l10n.t('Please enter a name')
            }]
          },
          emailRequired: {
            identifier: 'speaker_email_required',
            rules: [{
              type: 'empty',
              prompt: this.l10n.t('Please enter an email')
            }, {
              type: 'email',
              prompt: this.l10n.t('Please enter a valid email address')
            }]
          },
          email: {
            identifier: 'speaker_email',
            optional: true,
            rules: [{
              type: 'empty',
              prompt: this.l10n.t('Please enter an email')
            }, {
              type: 'email',
              prompt: this.l10n.t('Please enter a valid email address')
            }]
          },
          photoUrlRequired: {
            identifier: 'speaker_photoUrl_required',
            rules: [{
              type: 'empty',
              prompt: this.l10n.t('Please select an image')
            }, {
              type: 'regExp',
              value: _validators.compulsoryProtocolValidUrlPattern,
              prompt: this.l10n.t('Please enter a valid url')
            }]
          },
          photoUrl: {
            identifier: 'speaker_photoUrl',
            optional: true,
            rules: [{
              type: 'regExp',
              value: _validators.compulsoryProtocolValidUrlPattern,
              prompt: this.l10n.t('Please enter a valid url')
            }]
          },
          organisation: {
            identifier: 'speaker_organisation_required',
            rules: [{
              type: 'empty',
              prompt: this.l10n.t('Please enter an organisation')
            }]
          },
          position: {
            identifier: 'speaker_position_required',
            rules: [{
              type: 'empty',
              prompt: this.l10n.t('Please enter a position')
            }]
          },
          country: {
            identifier: 'speaker_country_required',
            rules: [{
              type: 'empty',
              prompt: this.l10n.t('Please enter a country')
            }]
          },
          city: {
            identifier: 'speaker_city_required',
            rules: [{
              type: 'empty',
              prompt: this.l10n.t('Please enter a city')
            }]
          },
          longBiography: {
            identifier: 'speaker_longBiography_required',
            rules: [{
              type: 'empty',
              prompt: this.l10n.t('Please enter long biography')
            }]
          },
          shortBiography: {
            identifier: 'speaker_shortBiography_required',
            rules: [{
              type: 'empty',
              prompt: this.l10n.t('Please enter short biography')
            }]
          },
          speakingExperience: {
            identifier: 'speaker_speakingExperience_required',
            rules: [{
              type: 'empty',
              prompt: this.l10n.t('Please enter speaking experience')
            }]
          },
          sponsorshipRequired: {
            identifier: 'speaker_sponsorshipRequired_required',
            rules: [{
              type: 'empty',
              prompt: this.l10n.t('Please enter select sponsorship')
            }]
          },
          gender: {
            identifier: 'speaker_gender_required',
            rules: [{
              type: 'empty',
              prompt: this.l10n.t('Please select a gender')
            }]
          },
          heardFrom: {
            identifier: 'speaker_heardFrom_required',
            rules: [{
              type: 'empty',
              prompt: this.l10n.t('Please enter where you heard about the event')
            }]
          },
          mobile: {
            identifier: 'speaker_mobile',
            optional: true,
            rules: [{
              type: 'regExp',
              value: _validators.validPhoneNumber,
              prompt: this.l10n.t('Please enter a valid mobile number.')
            }]
          },
          mobileRequired: {
            identifier: 'speaker_mobile_required',
            rules: [{
              type: 'empty',
              prompt: this.l10n.t('Please enter a mobile number')
            }, {
              type: 'regExp',
              value: _validators.validPhoneNumber,
              prompt: this.l10n.t('Please enter a valid mobile number.')
            }]
          },
          websiteRequired: {
            identifier: 'speaker_website_required',
            rules: [{
              type: 'empty',
              prompt: this.l10n.t('Please enter url of website')
            }, {
              type: 'regExp',
              value: _validators.protocolLessValidUrlPattern,
              prompt: this.l10n.t('Please enter a valid url')
            }]
          },
          website: {
            identifier: 'speaker_website',
            optional: true,
            rules: [{
              type: 'regExp',
              value: _validators.protocolLessValidUrlPattern,
              prompt: this.l10n.t('Please enter a valid url')
            }]
          }
        }
      };

      var addCustomFieldRules = function addCustomFieldRules(form) {
        _this.allFields[form].filter(function (field) {
          return field.isComplex && field.isRequired;
        }).forEach(function (field) {
          validationRules.fields["".concat(form, "_").concat(field.fieldIdentifier, "_required")] = {
            rules: [{
              type: 'empty',
              prompt: _this.l10n.t('Please enter {{field}}', {
                field: field.name
              })
            }]
          };
        });
      };

      addCustomFieldRules('speaker');
      addCustomFieldRules('session');
      return validationRules;
    },
    countries: (0, _lodashEs.orderBy)(_demography.countries, 'name'),
    languages: (0, _lodashEs.orderBy)(_languages.languages, 'name'),
    genders: (0, _lodashEs.orderBy)(_genders.genders, 'name'),
    allFields: Ember.computed('fields', function () {
      var grouped = (0, _lodashEs.groupBy)(this.fields.toArray(), function (field) {
        return field.get('form');
      });
      grouped.speaker = (0, _sort.sortCustomFormFields)(grouped.speaker, _customForm.SPEAKER_FORM_ORDER);
      grouped.session = (0, _sort.sortCustomFormFields)(grouped.session, _customForm.SESSION_FORM_ORDER);
      return grouped;
    }),
    // Clicking on the add session button creates a blank record which increases the length of the speaker's list by 1.
    noSpeakerExists: Ember.computed('speakers', function () {
      return this.speakers.length === 1;
    }),
    isUserOwnerOrAdmin: Ember.computed('event.owner.email', 'authManager.currentUser', function () {
      return this.authManager.currentUser.isAnAdmin || this.event.owner.get('email') === this.authManager.currentUser.get('email');
    }),
    isUserOrganiser: Ember.computed('event.organizers', 'authManager.currentUser', function () {
      var currentUser = this.authManager.currentUser;
      return this.event.organizers.includes(currentUser);
    }),
    // Clicking on the add speaker button creates a blank record which increases the length of the session's list by 1.
    noSessionExists: Ember.computed('sessions', function () {
      return this.sessions.length === 1;
    }),
    shouldShowNewSessionDetails: Ember.computed('sessionDetails', 'newSessionSelected', function () {
      return this.newSessionSelected && !this.sessionDetails;
    }),
    actions: {
      submit: function submit() {
        var _this2 = this;

        this.onValid(function () {
          _this2.sendAction('save');
        });
      },
      toggleNewSessionSelected: function toggleNewSessionSelected(value) {
        this.set('sessionDetails', null);
        this.set('newSessionSelected', !value);
      }
    },
    didInsertElement: function didInsertElement() {
      if (this.isSpeaker && this.data.speaker && this.data.speaker.length) {
        this.set('data.speaker', this.data.speaker.toArray()[0]);
      }

      if (this.isSession && this.data.session && this.data.session.length) {
        this.set('data.session', this.data.session.toArray()[0]);
      }
    }
  });

  _exports.default = _default;
});
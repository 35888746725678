define("open-event-frontend/templates/components/ui-table/cell/cell-event-date", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PtxS77Vl",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,2],[[32,0,[\"record\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,2],[[32,0,[\"extraRecords\",\"endsAt\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[12],[2,\"\\n      \"],[1,[30,[36,1],[[32,0,[\"record\"]],\"date-time-tz-short\"],[[\"tz\"],[[32,0,[\"extraRecords\",\"timezone\"]]]]]],[2,\"\\n    \"],[13],[2,\"\\n    (to)\\n    \"],[10,\"div\"],[12],[2,\"\\n      \"],[1,[30,[36,1],[[32,0,[\"extraRecords\",\"endsAt\"]],\"date-time-tz-short\"],[[\"tz\"],[[32,0,[\"extraRecords\",\"timezone\"]]]]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"span\"],[12],[2,\"\\n      \"],[1,[30,[36,0],[\"No dates available.\"],null]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"span\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"No dates available.\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"general-date\",\"if\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/ui-table/cell/cell-event-date.hbs"
    }
  });

  _exports.default = _default;
});
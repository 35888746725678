define("open-event-frontend/adapters/discount-code", ["exports", "open-event-frontend/adapters/application", "open-event-frontend/config/environment"], function (_exports, _application, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    urlForQueryRecord: function urlForQueryRecord(query) {
      if (query && query.code && query.eventIdentifier) {
        return "".concat(_environment.default.APP.apiHost, "/v1/events/").concat(query.eventIdentifier, "/discount-codes/").concat(query.code);
      } else {
        return this._super.apply(this, arguments);
      }
    }
  });

  _exports.default = _default;
});
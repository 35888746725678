define("open-event-frontend/templates/components/ui-table/cell/events/view/tickets/discount-codes/cell-url", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HwYcsfGd",
    "block": "{\"symbols\":[],\"statements\":[[10,\"a\"],[15,6,[31,[[32,0,[\"record\"]]]]],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener\"],[12],[2,\" \"],[1,[32,0,[\"record\"]]],[2,\" \"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/ui-table/cell/events/view/tickets/discount-codes/cell-url.hbs"
    }
  });

  _exports.default = _default;
});
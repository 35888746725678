define("open-event-frontend/utils/form", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.resetFormElement = void 0;

  var resetFormElement = function resetFormElement(e) {
    e = (0, _jquery.default)(e);
    e.wrap('<form>').closest('form').get(0).reset();
    e.unwrap();
  };

  _exports.resetFormElement = resetFormElement;
});
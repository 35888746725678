define("open-event-frontend/adapters/event", ["exports", "open-event-frontend/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    buildURL: function buildURL(modelName, id, snapshot, requestType, query) {
      var _snapshot$adapterOpti;

      var url = this._super(modelName, id, snapshot, requestType, query);

      if (requestType === 'updateRecord' && ((_snapshot$adapterOpti = snapshot.adapterOptions) === null || _snapshot$adapterOpti === void 0 ? void 0 : _snapshot$adapterOpti.getTrashed)) {
        return url + '?get_trashed=true';
      }

      if (query === null || query === void 0 ? void 0 : query.upcoming) {
        return url + '/upcoming';
      }

      return url;
    }
  });

  _exports.default = _default;
});
define("open-event-frontend/templates/components/ui-table/cell/events/cell-action", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OyuYYNLp",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,2],[[30,[36,1],[[32,0,[\"extraRecords\",\"status\"]],\"due\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[8,\"link-to\",[[24,0,\"ui blue button\"]],[[\"@route\",\"@model\"],[\"event-invoice.review\",[32,0,[\"record\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n   \\t\"],[1,[30,[36,0],[\"Review and Pay\"],null]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[8,\"link-to\",[[24,0,\"ui green button\"]],[[\"@route\",\"@model\"],[\"event-invoice.paid\",[32,0,[\"record\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[30,[36,0],[\"See Payment\"],null]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"eq\",\"if\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/ui-table/cell/events/cell-action.hbs"
    }
  });

  _exports.default = _default;
});
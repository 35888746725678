define("open-event-frontend/templates/components/ui-table/cell/cell-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CTzNw7Xe",
    "block": "{\"symbols\":[],\"statements\":[[10,\"a\"],[15,6,[31,[[32,0,[\"record\"]]]]],[12],[2,\"\\n  \"],[1,[32,0,[\"record\"]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/ui-table/cell/cell-link.hbs"
    }
  });

  _exports.default = _default;
});
define("open-event-frontend/utils/patches/fullcalendar", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.patchFullCalendar = patchFullCalendar;

  // Workaround for https://github.com/fossasia/open-event-frontend/issues/4778
  function patchFullCalendar() {
    var rxhtmlTag = /<(?!area|br|col|embed|hr|img|input|link|meta|param)(([a-z][^\/\0>\x20\t\r\n\f]*)[^>]*)\/>/gi;

    _jquery.default.htmlPrefilter = function (html) {
      return html.replace(rxhtmlTag, '<$1></$2>');
    };
  }
});
define("open-event-frontend/components/events/event-import-section", ["exports", "open-event-frontend/mixins/form"], function (_exports, _form) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_form.default, {
    classNames: ['ui', 'stackable', 'centered', 'grid'],
    getValidationRules: function getValidationRules() {
      return {
        inline: true,
        delay: false,
        on: 'blur',
        fields: {
          file: {
            identifier: 'file',
            rules: [{
              type: 'empty',
              prompt: this.l10n.t('Please upload a file')
            }, {
              type: 'regExp',
              value: '/^(.*.((zip|xml|ical|ics|xcal)$))?[^.]*$/i',
              prompt: this.l10n.t('Please upload a file in suggested format')
            }]
          }
        }
      };
    },
    actions: {
      onFileSelected: function onFileSelected(files) {
        this.set('files', files);
      },
      submit: function submit() {
        var _this = this;

        this.onValid(function () {
          _this.uploadFile(_this.files);
        });
      }
    }
  });

  _exports.default = _default;
});
define("open-event-frontend/models/session-type", ["exports", "ember-data/attr", "open-event-frontend/models/base", "ember-data/relationships"], function (_exports, _attr, _base, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    name: (0, _attr.default)('string'),
    length: (0, _attr.default)('string', {
      defaultValue: '00:30'
    }),
    event: (0, _relationships.belongsTo)('event'),
    sessions: (0, _relationships.hasMany)('session')
  });

  _exports.default = _default;
});
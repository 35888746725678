define("open-event-frontend/helpers/custom-related-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.customRelatedField = customRelatedField;
  _exports.default = void 0;

  function customRelatedField(params
  /* , hash*/
  ) {
    var record = params[0].get(params[1]);
    return record.get(params[2]);
  }

  var _default = Ember.Helper.helper(customRelatedField);

  _exports.default = _default;
});
define("open-event-frontend/components/widgets/forms/date-picker", ["exports", "jquery", "lodash-es", "moment", "open-event-frontend/utils/dictionary/date-time", "open-event-frontend/mixins/form"], function (_exports, _jquery, _lodashEs, _moment, _dateTime, _form) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_form.default, {
    classNames: ['ui', 'calendar', 'date', 'picker', 'input', 'fluid'],
    classNameBindings: ['icon:left', 'icon'],
    icon: true,
    today: true,
    rangePosition: 'none',
    format: _dateTime.FORM_DATE_FORMAT,
    options: {},
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      var defaultOptions = {
        type: 'date',
        today: this.today,
        formatter: {
          date: function date(_date) {
            if (!_date) {
              return '';
            }

            return (0, _moment.default)(_date).format(_this.format);
          }
        }
      };

      switch (this.rangePosition) {
        case 'start':
          defaultOptions.endCalendar = (0, _jquery.default)(this.element).closest('.fields').find('.ui.calendar.date.picker');
          break;

        case 'end':
          defaultOptions.startCalendar = (0, _jquery.default)(this.element).closest('.fields').find('.ui.calendar.date.picker');
          break;
      }

      (0, _jquery.default)(this.element).calendar((0, _lodashEs.merge)(defaultOptions, this.options));
    },
    actions: {
      onChange: function onChange() {
        if (this.onChange) {
          this.sendAction('onChange');
        }
      }
    }
  });

  _exports.default = _default;
});
define("open-event-frontend/templates/events/view/team/permissions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gy0RxqzF",
    "block": "{\"symbols\":[\"permission\"],\"statements\":[[10,\"table\"],[14,0,\"ui center aligned stackable celled structured table\"],[12],[2,\"\\n  \"],[10,\"thead\"],[12],[2,\"\\n    \"],[10,\"tr\"],[12],[2,\"\\n      \"],[10,\"th\"],[14,0,\"center aligned\"],[12],[1,[30,[36,1],[\"Permissions\"],null]],[13],[2,\"\\n      \"],[10,\"th\"],[14,0,\"center aligned\"],[12],[1,[30,[36,1],[\"Owner\"],null]],[13],[2,\"\\n      \"],[10,\"th\"],[14,0,\"center aligned\"],[12],[1,[30,[36,1],[\"Organizer\"],null]],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"tbody\"],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[32,0,[\"model\",\"permissions\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"tr\"],[12],[2,\"\\n\"],[6,[37,0],[[32,1,[\"header\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"          \"],[10,\"td\"],[14,\"colspan\",\"3\"],[14,0,\"left aligned\"],[14,5,\"background-color: rgba(0, 0, 0, 0.05);\"],[12],[1,[32,1,[\"title\"]]],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"          \"],[10,\"td\"],[14,0,\"left aligned\"],[12],[1,[32,1,[\"title\"]]],[13],[2,\"\\n          \"],[10,\"td\"],[12],[2,\"\\n\"],[6,[37,0],[[32,1,[\"owner\"]]],null,[[\"default\"],[{\"statements\":[[2,\"              \"],[10,\"i\"],[14,0,\"check icon\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"          \"],[13],[2,\"\\n          \"],[10,\"td\"],[12],[2,\"\\n\"],[6,[37,0],[[32,1,[\"organizer\"]]],null,[[\"default\"],[{\"statements\":[[2,\"              \"],[10,\"i\"],[14,0,\"check icon\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"          \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"      \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"t\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/events/view/team/permissions.hbs"
    }
  });

  _exports.default = _default;
});
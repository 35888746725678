define("open-event-frontend/initializers/extensions", ["exports", "open-event-frontend/extensions/ember-table/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize() {}

  var _default = {
    name: 'extensions',
    initialize: initialize
  };
  _exports.default = _default;
});
define("open-event-frontend/models/track", ["exports", "ember-data/attr", "open-event-frontend/models/base", "ember-data/relationships", "open-event-frontend/utils/color"], function (_exports, _attr, _base, _relationships, _color) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    name: (0, _attr.default)('string'),
    color: (0, _attr.default)('string', {
      defaultValue: function defaultValue() {
        return (0, _color.getRandomColor)();
      }
    }),
    description: (0, _attr.default)('string'),
    fontColor: (0, _attr.default)('string'),
    sessions: (0, _relationships.hasMany)('session'),
    event: (0, _relationships.belongsTo)('event')
  });

  _exports.default = _default;
});
define("open-event-frontend/templates/components/modals/cropper-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FxaE1irj",
    "block": "{\"symbols\":[],\"statements\":[[10,\"i\"],[14,0,\"black close icon\"],[12],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"header\"],[12],[2,\"\\n  \"],[1,[30,[36,0],[\"Crop Image\"],null]],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"content\"],[14,5,\"padding: 0; margin: 0;\"],[12],[2,\"\\n  \"],[10,\"img\"],[15,\"src\",[31,[[32,0,[\"imgData\"]]]]],[14,5,\"width: 100%; height: auto; margin: 0; padding: 0;\"],[14,\"alt\",\"Cropper preview\"],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"actions\"],[12],[2,\"\\n  \"],[11,\"button\"],[24,0,\"ui black button\"],[24,4,\"button\"],[4,[38,1],[[32,0],\"resetImage\"],null],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"Reset\"],null]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[11,\"button\"],[24,0,\"ui green right labeled icon button\"],[24,4,\"button\"],[4,[38,1],[[32,0],\"cropImage\"],null],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"Looks good\"],null]],[2,\"\\n    \"],[10,\"i\"],[14,0,\"checkmark icon\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"action\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/modals/cropper-modal.hbs"
    }
  });

  _exports.default = _default;
});
define("open-event-frontend/templates/my-tickets/upcoming", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "w6DzpzLO",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"sixteen wide column\"],[12],[2,\"\\n    \"],[8,\"tabbed-navigation\",[],[[\"@isNonPointing\"],[true]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,\"link-to\",[[24,0,\"item\"]],[[\"@route\",\"@model\"],[\"my-tickets.upcoming.list\",\"completed\"]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[30,[36,0],[\"Completed Orders\"],null]],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n      \"],[8,\"link-to\",[[24,0,\"item\"]],[[\"@route\",\"@model\"],[\"my-tickets.upcoming.list\",\"open\"]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[30,[36,0],[\"Open Orders\"],null]],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[1,[30,[36,2],[[30,[36,1],null,null]],null]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/my-tickets/upcoming.hbs"
    }
  });

  _exports.default = _default;
});
define("open-event-frontend/templates/components/ui-table/cell/admin/messages/cell-options", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tOErgbAt",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"ui checkbox\"],[12],[2,\"\\n  \"],[8,\"input\",[],[[\"@type\",\"@name\",\"@checked\"],[\"checkbox\",\"mail_status\",[32,0,[\"extraRecords\",\"mailStatus\"]]]],null],[2,\"\\n  \"],[10,\"label\"],[12],[2,\"Mail\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"ui checkbox\"],[12],[2,\"\\n  \"],[8,\"input\",[],[[\"@type\",\"@name\",\"@checked\"],[\"checkbox\",\"notification_status\",[32,0,[\"extraRecords\",\"notificationStatus\"]]]],null],[2,\"\\n  \"],[10,\"label\"],[12],[2,\"Notification\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"ui checkbox\"],[12],[2,\"\\n  \"],[8,\"input\",[],[[\"@type\",\"@name\",\"@checked\"],[\"checkbox\",\"user_control_status\",[32,0,[\"extraRecords\",\"userControlStatus\"]]]],null],[2,\"\\n  \"],[10,\"label\"],[12],[2,\"User Control\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/ui-table/cell/admin/messages/cell-options.hbs"
    }
  });

  _exports.default = _default;
});
define("open-event-frontend/models/speaker-image-size", ["exports", "ember-data/attr", "open-event-frontend/models/base", "open-event-frontend/mixins/custom-primary-key"], function (_exports, _attr, _base, _customPrimaryKey) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend(_customPrimaryKey.default, {
    thumbnailSizeQuality: (0, _attr.default)('number'),
    type: (0, _attr.default)('string'),
    smallSizeWidthHeight: (0, _attr.default)('number'),
    smallSizeQuality: (0, _attr.default)('number'),
    iconSizeQuality: (0, _attr.default)('number'),
    iconSizeWidthHeight: (0, _attr.default)('number'),
    thumbnailSizeWidthHeight: (0, _attr.default)('number')
  });

  _exports.default = _default;
});
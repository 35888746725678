define("open-event-frontend/templates/public/cfs/new-session", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hPeAcpxi",
    "block": "{\"symbols\":[\"speaker\"],\"statements\":[[10,\"div\"],[14,0,\"ui container\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"column sixteen wide\"],[12],[2,\"\\n    \"],[10,\"h2\"],[14,0,\"ui header center aligned\"],[12],[2,\"\\n      \"],[1,[30,[36,2],[\"Session Details\"],null]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"ui container\"],[12],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[32,0,[\"model\",\"speaker\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,1],[[32,1,[\"id\"]]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[8,\"forms/session-speaker-form\",[],[[\"@fields\",\"@data\",\"@isLoading\",\"@save\",\"@isSession\",\"@includeSession\"],[[32,0,[\"model\",\"forms\"]],[32,0,[\"model\"]],[32,0,[\"isLoading\"]],[30,[36,0],[[32,0],\"save\",[32,1]],null],true,true]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"if\",\"t\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/public/cfs/new-session.hbs"
    }
  });

  _exports.default = _default;
});
define("open-event-frontend/templates/register", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gpXPrNtO",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"ui three column container stackable doubling centered grid\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"column\"],[12],[2,\"\\n    \"],[8,\"forms/register-form\",[],[[\"@data\",\"@submit\",\"@isLoading\",\"@errorMessage\",\"@inviteEmail\"],[[32,0,[\"model\"]],\"createUser\",[32,0,[\"isLoading\"]],[32,0,[\"errorMessage\"]],[32,0,[\"inviteEmail\"]]]],null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/register.hbs"
    }
  });

  _exports.default = _default;
});
define("open-event-frontend/components/forms/orders/order-form", ["exports", "open-event-frontend/mixins/form", "moment", "lodash-es", "open-event-frontend/utils/validators", "open-event-frontend/utils/dictionary/genders", "open-event-frontend/utils/dictionary/age-groups", "open-event-frontend/utils/dictionary/demography"], function (_exports, _form, _moment, _lodashEs, _validators, _genders, _ageGroups, _demography) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_form.default, {
    router: Ember.inject.service(),
    buyerFirstName: Ember.computed.oneWay('buyerHasFirstName'),
    buyerLastName: Ember.computed.oneWay('buyerHasLastName'),
    buyer: Ember.computed.readOnly('data.user'),
    buyerHasFirstName: Ember.computed.readOnly('data.user.firstName'),
    buyerHasLastName: Ember.computed.readOnly('data.user.lastName'),
    holders: Ember.computed('data.attendees', 'buyer', function () {
      var _this = this;

      this.data.attendees.forEach(function (attendee, index) {
        if (index === 0) {
          attendee.set('firstname', _this.buyerFirstName);
          attendee.set('lastname', _this.buyerLastName);
          attendee.set('email', _this.buyer.get('email'));
        } else {
          attendee.set('firstname', '');
          attendee.set('lastname', '');
          attendee.set('email', '');
        }
      });
      return this.data.attendees;
    }),
    isPaidOrder: Ember.computed('data', function () {
      if (!this.data.amount) {
        this.data.set('paymentMode', 'free');
        return false;
      }

      return true;
    }),
    sameAsBuyer: true,
    isBillingInfoNeeded: Ember.computed('event', 'data.isBillingEnabled', function () {
      return this.event.isBillingInfoMandatory || this.data.isBillingEnabled;
    }),
    getRemainingTime: Ember.computed('settings', function () {
      var orderExpiryTime = this.settings.orderExpiryTime;
      var willExpireAt = this.data.createdAt.add(orderExpiryTime, 'minutes');
      this.timer(willExpireAt, this.data.identifier);
    }),
    timer: function timer(willExpireAt, orderIdentifier) {
      var _this2 = this;

      Ember.run.later(function () {
        var currentTime = (0, _moment.default)();

        var diff = _moment.default.duration(willExpireAt.diff(currentTime));

        if (diff > 0) {
          _this2.set('getRemainingTime', _moment.default.utc(diff.asMilliseconds()).format('mm:ss'));

          _this2.timer(willExpireAt, orderIdentifier);
        } else {
          _this2.set('getRemainingTime', '00:00');

          _this2.data.set('status', 'expired');

          _this2.data.reload();

          _this2.router.transitionTo('orders.expired', orderIdentifier);
        }
      }, 1000);
    },
    getValidationRules: function getValidationRules() {
      var _this3 = this;

      var firstNameValidation = {
        rules: [{
          type: 'empty',
          prompt: this.l10n.t('Please enter your first name')
        }]
      };
      var lastNameValidation = {
        rules: [{
          type: 'empty',
          prompt: this.l10n.t('Please enter your last name')
        }]
      };
      var emailValidation = {
        rules: [{
          type: 'empty',
          prompt: this.l10n.t('Please enter your email')
        }, {
          type: 'regExp',
          value: _validators.validEmail,
          prompt: this.l10n.t('Please enter a valid email address')
        }]
      };
      var genderValidation = {
        rules: [{
          type: 'empty',
          prompt: this.l10n.t('Please select a gender')
        }]
      };
      var ageGroupValidation = {
        rules: [{
          type: 'empty',
          prompt: this.l10n.t('Please select your age group')
        }]
      };
      var addressValidation = {
        rules: [{
          type: 'empty',
          prompt: this.l10n.t('Please enter your address')
        }]
      };
      var cityValidation = {
        rules: [{
          type: 'empty',
          prompt: this.l10n.t('Please enter your city')
        }]
      };
      var stateValidation = {
        rules: [{
          type: 'empty',
          prompt: this.l10n.t('Please enter your state')
        }]
      };
      var countryValidation = {
        rules: [{
          type: 'empty',
          prompt: this.l10n.t('Please select your country')
        }]
      };
      var jobTitleValidation = {
        rules: [{
          type: 'empty',
          prompt: this.l10n.t('Please enter your job title')
        }]
      };
      var phoneValidation = {
        rules: [{
          type: 'empty',
          prompt: this.l10n.t('Please enter a mobile number')
        }]
      };
      var taxBusinessInfoValidation = {
        rules: [{
          type: 'empty',
          prompt: this.l10n.t('Please enter your tax business info')
        }]
      };
      var billingAddressValidation = {
        rules: [{
          type: 'empty',
          prompt: this.l10n.t('Please enter your billing address')
        }]
      };
      var homeAddressValidation = {
        rules: [{
          type: 'empty',
          prompt: this.l10n.t('Please enter your home address')
        }]
      };
      var shippingAddressValidation = {
        rules: [{
          type: 'empty',
          prompt: this.l10n.t('Please enter your shipping address')
        }]
      };
      var companyValidation = {
        rules: [{
          type: 'empty',
          prompt: this.l10n.t('Please enter your company')
        }]
      };
      var workAddressValidation = {
        rules: [{
          type: 'empty',
          prompt: this.l10n.t('Please enter your work address')
        }]
      };
      var workPhoneValidation = {
        rules: [{
          type: 'empty',
          prompt: this.l10n.t('Please enter your work phone')
        }]
      };
      var websiteValidation = {
        optional: true,
        rules: [{
          type: 'regExp',
          value: _validators.compulsoryProtocolValidUrlPattern,
          prompt: this.l10n.t('Please enter a valid url')
        }]
      };
      var websiteRequiredValidation = {
        rules: [{
          type: 'empty',
          prompt: this.l10n.t('Please enter url of website')
        }, {
          type: 'regExp',
          value: _validators.compulsoryProtocolValidUrlPattern,
          prompt: this.l10n.t('Please enter a valid url')
        }]
      };
      var blogValidation = {
        optional: true,
        rules: [{
          type: 'regExp',
          value: _validators.compulsoryProtocolValidUrlPattern,
          prompt: this.l10n.t('Please enter a valid url')
        }]
      };
      var blogRequiredValidation = {
        rules: [{
          type: 'empty',
          prompt: this.l10n.t('Please enter url of website')
        }, {
          type: 'regExp',
          value: _validators.compulsoryProtocolValidUrlPattern,
          prompt: this.l10n.t('Please enter a valid url')
        }]
      };
      var twitterValidation = {
        optional: true,
        rules: [{
          type: 'regExp',
          value: _validators.validTwitterProfileUrlPattern,
          prompt: this.l10n.t('Please enter a valid twitter profile url')
        }]
      };
      var twitterRequiredValidation = {
        rules: [{
          type: 'empty',
          prompt: this.l10n.t('Please enter twitter link')
        }, {
          type: 'regExp',
          value: _validators.validTwitterProfileUrlPattern,
          prompt: this.l10n.t('Please enter a valid twitter profile url')
        }]
      };
      var facebookValidation = {
        optional: true,
        rules: [{
          type: 'regExp',
          value: _validators.validFacebookProfileUrlPattern,
          prompt: this.l10n.t('Please enter a valid facebook account url')
        }]
      };
      var facebookRequiredValidation = {
        rules: [{
          type: 'empty',
          prompt: this.l10n.t('Please enter facebook link')
        }, {
          type: 'regExp',
          value: _validators.validFacebookProfileUrlPattern,
          prompt: this.l10n.t('Please enter a valid facebook account url')
        }]
      };
      var githubValidation = {
        optional: true,
        rules: [{
          type: 'regExp',
          value: _validators.validGithubProfileUrlPattern,
          prompt: this.l10n.t('Please enter a valid GitHub profile url')
        }]
      };
      var githubRequiredValidation = {
        rules: [{
          type: 'empty',
          prompt: this.l10n.t('Please enter GitHub link')
        }, {
          type: 'regExp',
          value: _validators.validGithubProfileUrlPattern,
          prompt: this.l10n.t('Please enter a valid GitHub profile url')
        }]
      };
      var validationRules = {
        inline: true,
        delay: false,
        on: 'blur',
        fields: {
          firstName: {
            identifier: 'first_name',
            rules: [{
              type: 'empty',
              prompt: this.l10n.t('Please enter your first name')
            }]
          },
          lastName: {
            identifier: 'last_name',
            rules: [{
              type: 'empty',
              prompt: this.l10n.t('Please enter your last name')
            }]
          },
          email: {
            identifier: 'email',
            rules: [{
              type: 'regExp',
              value: _validators.validEmail,
              prompt: this.l10n.t('Please enter a valid email address')
            }]
          },
          country: {
            identifier: 'country',
            rules: [{
              type: 'empty',
              prompt: this.l10n.t('Please select your country')
            }]
          },
          taxBusinessInfo: {
            identifier: 'taxBusinessInfo',
            rules: [{
              type: 'empty',
              prompt: this.l10n.t('Please enter your Tax ID or Business ID')
            }]
          },
          address: {
            identifier: 'address',
            rules: [{
              type: 'empty',
              prompt: this.l10n.t('Please enter your address')
            }]
          },
          city: {
            identifier: 'city',
            rules: [{
              type: 'empty',
              prompt: this.l10n.t('Please enter your city')
            }]
          },
          zipCode: {
            identifier: 'zip_code',
            rules: [{
              type: 'empty',
              prompt: this.l10n.t('Please enter your zip code')
            }]
          },
          payVia: {
            identifier: 'payment_mode',
            rules: [{
              type: 'checked',
              prompt: this.l10n.t('Please specify your choice of payment method ')
            }]
          }
        }
      };
      this.holders.forEach(function (value, index) {
        validationRules.fields["firstname_required_".concat(index)] = firstNameValidation;
        validationRules.fields["lastname_required_".concat(index)] = lastNameValidation;
        validationRules.fields["email_required_".concat(index)] = emailValidation;
        validationRules.fields["gender_required_".concat(index)] = genderValidation;
        validationRules.fields["ageGroup_required_".concat(index)] = ageGroupValidation;
        validationRules.fields["address_required_".concat(index)] = addressValidation;
        validationRules.fields["city_required_".concat(index)] = cityValidation;
        validationRules.fields["state_required_".concat(index)] = stateValidation;
        validationRules.fields["country_required_".concat(index)] = countryValidation;
        validationRules.fields["jobTitle_required_".concat(index)] = jobTitleValidation;
        validationRules.fields["phone_required_".concat(index)] = phoneValidation;
        validationRules.fields["taxBusinessInfo_required_".concat(index)] = taxBusinessInfoValidation;
        validationRules.fields["billingAddress_required_".concat(index)] = billingAddressValidation;
        validationRules.fields["homeAddress_required_".concat(index)] = homeAddressValidation;
        validationRules.fields["shippingAddress_required_".concat(index)] = shippingAddressValidation;
        validationRules.fields["company_required_".concat(index)] = companyValidation;
        validationRules.fields["workAddress_required_".concat(index)] = workAddressValidation;
        validationRules.fields["workPhone_required_".concat(index)] = workPhoneValidation;
        validationRules.fields["website_".concat(index)] = websiteValidation;
        validationRules.fields["website_required_".concat(index)] = websiteRequiredValidation;
        validationRules.fields["blog_".concat(index)] = blogValidation;
        validationRules.fields["blog_required_".concat(index)] = blogRequiredValidation;
        validationRules.fields["twitter_".concat(index)] = twitterValidation;
        validationRules.fields["twitter_required_".concat(index)] = twitterRequiredValidation;
        validationRules.fields["facebook_".concat(index)] = facebookValidation;
        validationRules.fields["facebook_required_".concat(index)] = facebookRequiredValidation;
        validationRules.fields["github_".concat(index)] = githubValidation;
        validationRules.fields["github_required_".concat(index)] = githubRequiredValidation;

        _this3.allFields.attendee.filter(function (field) {
          return field.isComplex && field.isRequired;
        }).forEach(function (field) {
          validationRules.fields["".concat(field.fieldIdentifier, "_required_").concat(index)] = {
            rules: [{
              type: 'empty',
              prompt: _this3.l10n.t('Please enter {{field}}', {
                field: field.name
              })
            }]
          };
        });
      });
      return validationRules;
    },
    allFields: Ember.computed('fields', function () {
      return (0, _lodashEs.groupBy)(this.fields.toArray(), function (field) {
        return field.get('form');
      });
    }),
    genders: (0, _lodashEs.orderBy)(_genders.genders, 'name'),
    ageGroups: (0, _lodashEs.orderBy)(_ageGroups.ageGroups, 'age'),
    countries: (0, _lodashEs.orderBy)(_demography.countries, 'name'),
    actions: {
      submit: function submit(data) {
        var _this4 = this;

        this.onValid(function () {
          var currentUser = _this4.data.user;
          currentUser.set('firstName', _this4.buyerFirstName);
          currentUser.set('lastName', _this4.buyerLastName);

          _this4.sendAction('save', data);
        });
      },
      modifyHolder: function modifyHolder(holder) {
        if (this.sameAsBuyer) {
          holder.set('firstname', this.buyerFirstName);
          holder.set('lastname', this.buyerLastName);
          holder.set('email', this.buyer.content.email);
        } else {
          holder.set('firstname', '');
          holder.set('lastname', '');
          holder.set('email', '');
        }
      }
    }
  });

  _exports.default = _default;
});
define("open-event-frontend/templates/components/forms/admin/content/translation-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "InGdZQus",
    "block": "{\"symbols\":[\"language\"],\"statements\":[[11,\"form\"],[24,0,\"ui form\"],[4,[38,1],[[32,0],\"submit\"],[[\"on\"],[\"submit\"]]],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"field\"],[12],[2,\"\\n    \"],[8,\"input\",[],[[\"@type\",\"@name\"],[\"file\",\"file\"]],null],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"field\"],[12],[2,\"\\n    \"],[8,\"ui-dropdown\",[],[[\"@class\"],[\"selection fluid\"]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,\"input\",[],[[\"@type\",\"@name\"],[\"hidden\",\"language\"]],null],[2,\"\\n      \"],[10,\"div\"],[14,0,\"default text\"],[12],[1,[30,[36,2],[\"Language\"],null]],[13],[2,\"\\n      \"],[10,\"i\"],[14,0,\"dropdown icon\"],[12],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"menu\"],[12],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[32,0,[\"languages\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[10,\"div\"],[14,0,\"item\"],[15,\"data-value\",[31,[[32,1,[\"code\"]]]]],[12],[2,\"\\n            \"],[1,[30,[36,0],[[32,0,[\"l10n\",\"availableLocales\"]],[32,1,[\"code\"]]],null]],[2,\"\\n          \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"      \"],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"button\"],[14,3,\"button\"],[14,0,\"ui blue button\"],[14,4,\"submit\"],[12],[1,[30,[36,2],[\"Upload\"],null]],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"get\",\"action\",\"t\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/forms/admin/content/translation-form.hbs"
    }
  });

  _exports.default = _default;
});
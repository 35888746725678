define("open-event-frontend/templates/components/ui-table/cell/events/view/tickets/attendees/cell-action", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "q87rUrkz",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,2],[[30,[36,3],[[32,0,[\"extraRecords\",\"order\",\"status\"]],\"completed\"],null]],null,[[\"default\"],[{\"statements\":[[6,[37,2],[[32,0,[\"extraRecords\",\"isCheckedIn\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[11,\"button\"],[24,0,\"tiny ui labeled icon negative button\"],[4,[38,0],[[32,0],[32,0,[\"props\",\"actions\",\"toggleCheckIn\"]],[32,0,[\"record\"]]],null],[12],[10,\"i\"],[14,0,\"remove icon\"],[12],[13],[1,[30,[36,1],[\"Check-Out\"],null]],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[11,\"button\"],[24,0,\"tiny ui labeled icon positive button\"],[4,[38,0],[[32,0],[32,0,[\"props\",\"actions\",\"toggleCheckIn\"]],[32,0,[\"record\"]]],null],[12],[10,\"i\"],[14,0,\"checkmark icon\"],[12],[13],[1,[30,[36,1],[\"Check‑In\"],null]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"t\",\"if\",\"eq\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/ui-table/cell/events/view/tickets/attendees/cell-action.hbs"
    }
  });

  _exports.default = _default;
});
define("open-event-frontend/components/g-map-route-address-waypoint", ["exports", "ember-g-map/components/g-map-route-address-waypoint"], function (_exports, _gMapRouteAddressWaypoint) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _gMapRouteAddressWaypoint.default;
    }
  });
});
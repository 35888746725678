define("open-event-frontend/models/user", ["exports", "ember-data/attr", "open-event-frontend/models/base", "ember-data/relationships", "lodash-es"], function (_exports, _attr, _base, _relationships, _lodashEs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    authManager: Ember.inject.service(),
    email: (0, _attr.default)('string'),
    password: (0, _attr.default)('string'),
    isVerified: (0, _attr.default)('boolean', {
      readOnly: true
    }),
    isSuperAdmin: (0, _attr.default)('boolean', {
      readOnly: true
    }),
    isAdmin: (0, _attr.default)('boolean'),
    isUserOwner: (0, _attr.default)('boolean'),
    isUserOrganizer: (0, _attr.default)('boolean'),
    isUserCoorganizer: (0, _attr.default)('boolean'),
    isUserTrackOrganizer: (0, _attr.default)('boolean'),
    isUserModerator: (0, _attr.default)('boolean'),
    isUserRegistrar: (0, _attr.default)('boolean'),
    isSalesAdmin: (0, _attr.default)('boolean'),
    isMarketer: (0, _attr.default)('boolean'),
    wasRegisteredWithOrder: (0, _attr.default)('boolean'),
    firstName: (0, _attr.default)('string'),
    lastName: (0, _attr.default)('string'),
    details: (0, _attr.default)('string'),
    contact: (0, _attr.default)('string'),
    avatarUrl: (0, _attr.default)('string'),
    iconImageUrl: (0, _attr.default)('string'),
    smallImageUrl: (0, _attr.default)('string'),
    thumbnailImageUrl: (0, _attr.default)('string'),
    originalImageUrl: (0, _attr.default)('string'),
    facebookUrl: (0, _attr.default)('string'),
    instagramUrl: (0, _attr.default)('string'),
    twitterUrl: (0, _attr.default)('string'),
    googlePlusUrl: (0, _attr.default)('string'),
    facebookId: (0, _attr.default)('string', {
      readOnly: true
    }),
    createdAt: (0, _attr.default)('moment', {
      readOnly: true
    }),
    deletedAt: (0, _attr.default)('moment'),
    lastAccessedAt: (0, _attr.default)('moment', {
      readOnly: true
    }),

    /**
     * Billing Contact Information
     */
    billingContactName: (0, _attr.default)('string'),
    billingPhone: (0, _attr.default)('string'),
    billingCountry: (0, _attr.default)('string'),
    company: (0, _attr.default)('string'),
    billingAddress: (0, _attr.default)('string'),
    billingCity: (0, _attr.default)('string'),
    billingZipCode: (0, _attr.default)('string'),
    billingTaxInfo: (0, _attr.default)('string'),
    billingAdditionalInfo: (0, _attr.default)('string'),
    billingState: (0, _attr.default)('string'),
    status: Ember.computed('lastAccessedAt', 'deletedAt', function () {
      if (this.deletedAt == null) {
        if (this.lastAccessedAt == null) {
          return 'inactive';
        }

        return new Date().getMonth() - new Date(this.lastAccessedAt).getMonth() <= 12 ? 'active' : 'inactive';
      } else {
        return 'deleted';
      }
    }),
    isAnAdmin: Ember.computed.or('isSuperAdmin', 'isAdmin'),

    /**
     * Relationships
     */
    emailNotifications: (0, _relationships.hasMany)('email-notification'),
    notifications: (0, _relationships.hasMany)('notification'),
    orders: (0, _relationships.hasMany)('order'),
    events: (0, _relationships.hasMany)('event', {
      inverse: 'user'
    }),
    sessions: (0, _relationships.hasMany)('session'),
    feedbacks: (0, _relationships.hasMany)('feedback'),
    eventInvoices: (0, _relationships.hasMany)('event-invoice'),
    attendees: (0, _relationships.hasMany)('attendee'),
    speakers: (0, _relationships.hasMany)('speaker'),
    discountCodes: (0, _relationships.hasMany)('discount-code'),
    accessCodes: (0, _relationships.hasMany)('access-code'),
    ownerEvents: (0, _relationships.hasMany)('event'),
    organizerEvents: (0, _relationships.hasMany)('event'),
    coorganizerEvents: (0, _relationships.hasMany)('event'),
    trackOrganizerEvents: (0, _relationships.hasMany)('event'),
    registrarEvents: (0, _relationships.hasMany)('event'),
    moderatorEvents: (0, _relationships.hasMany)('event'),
    marketerEvents: (0, _relationships.hasMany)('event'),
    salesAdminEvents: (0, _relationships.hasMany)('event'),
    didUpdate: function didUpdate() {
      this._super.apply(this, arguments);

      if ((0, _lodashEs.toString)(this.id) === (0, _lodashEs.toString)(this.authManager.currentUser.id)) {
        var user = this.store.peekRecord('user', this.id);
        this.authManager.persistCurrentUser(user);
      }
    },
    fullName: Ember.computed('firstName', 'lastName', function () {
      return [this.firstName, this.lastName].filter(Boolean).join(' ');
    })
  });

  _exports.default = _default;
});
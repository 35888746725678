define("open-event-frontend/components/forms/events/view/create-discount-code", ["exports", "open-event-frontend/mixins/form", "open-event-frontend/helpers/currency-symbol"], function (_exports, _form, _currencySymbol) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _createForOfIteratorHelper(o, allowArrayLike) { var it; if (typeof Symbol === "undefined" || o[Symbol.iterator] == null) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = o[Symbol.iterator](); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = Ember.Component.extend(_form.default, {
    getValidationRules: function getValidationRules() {
      var _this = this;

      window.$.fn.form.settings.rules.checkMaxMin = function () {
        return _this.data.minQuantity <= _this.data.maxQuantity;
      };

      window.$.fn.form.settings.rules.checkMaxTotal = function () {
        return _this.data.maxQuantity <= _this.data.ticketsNumber;
      };

      window.$.fn.form.settings.rules.checkTicketSelected = function () {
        var tickets = _this.eventTickets;

        var _iterator = _createForOfIteratorHelper(tickets),
            _step;

        try {
          for (_iterator.s(); !(_step = _iterator.n()).done;) {
            var ticket = _step.value;

            if (ticket.isChecked) {
              return true;
            }
          }
        } catch (err) {
          _iterator.e(err);
        } finally {
          _iterator.f();
        }

        return false;
      }; // TODO: Removing the Discount Code Time Validations due to the weird and buggy behaviour. Will be restored once a perfect solution is found. Please check issue: https://github.com/fossasia/open-event-frontend/issues/3667


      return {
        inline: true,
        delay: false,
        on: 'blur',
        fields: {
          discountCode: {
            identifier: 'discount_code',
            rules: [{
              type: 'empty',
              prompt: this.l10n.t('Please enter a discount code')
            }, {
              type: 'regExp',
              value: '^[a-zA-Z0-9_-]*$'
            }]
          },
          numberOfdiscountTickets: {
            identifier: 'number_of_discount_tickets',
            rules: [{
              type: 'empty',
              prompt: this.l10n.t('Please enter the number of tickets')
            }]
          },
          discountAmount: {
            identifier: 'discount_amount',
            rules: [{
              type: 'empty',
              prompt: this.l10n.t('Please enter the discount amount')
            }]
          },
          discountPercent: {
            identifier: 'discount_percent',
            rules: [{
              type: 'empty',
              prompt: this.l10n.t('Please enter the discount percentage')
            }]
          },
          minOrder: {
            identifier: 'min_order',
            optional: true,
            rules: [{
              type: 'integer',
              prompt: this.l10n.t('Please enter a valid integer')
            }, {
              type: 'checkMaxMin',
              prompt: this.l10n.t('Minimum value should not be greater than maximum')
            }]
          },
          maxOrder: {
            identifier: 'max_order',
            optional: true,
            rules: [{
              type: 'integer',
              prompt: this.l10n.t('Please enter a valid integer')
            }, {
              type: 'checkMaxMin',
              prompt: this.l10n.t('Maximum value should not be less than minimum')
            }, {
              type: 'checkMaxTotal',
              prompt: this.l10n.t('Maximum value should not be greater than number of tickets')
            }]
          },
          ticketName: {
            identifier: 'ticket_name',
            rules: [{
              type: 'checkTicketSelected',
              prompt: this.l10n.t('Please select atleast 1 ticket.')
            }]
          }
        }
      };
    },
    discountLink: Ember.computed('data.code', function () {
      var params = this.router._router.currentState.routerJsState.params;
      var origin = this.fastboot.isFastBoot ? "".concat(this.fastboot.request.protocol, "//").concat(this.fastboot.request.host) : location.origin;
      var link = origin + this.router.urlFor('public', params['events.view'].event_id, {
        queryParams: {
          code: this.data.code
        }
      });
      this.set('data.discountUrl', link);
      return link;
    }),
    amountLabel: Ember.computed('event.paymentCurrency', function () {
      return "Amount (".concat((0, _currencySymbol.currencySymbol)([this.event.paymentCurrency]), ")");
    }),
    eventTickets: Ember.computed.filterBy('tickets', 'type', 'paid'),
    allTicketTypesChecked: Ember.computed('tickets', function () {
      if (this.eventTickets.length && this.data.tickets.length === this.eventTickets.length) {
        return true;
      }

      return false;
    }),
    isLinkSuccess: false,
    actions: {
      toggleAllSelection: function toggleAllSelection(allTicketTypesChecked) {
        this.toggleProperty('allTicketTypesChecked');
        var tickets = this.eventTickets;

        if (allTicketTypesChecked) {
          this.set('data.tickets', tickets.slice());
        } else {
          this.data.tickets.clear();
        }

        tickets.forEach(function (ticket) {
          ticket.set('isChecked', allTicketTypesChecked);
        });
      },
      updateTicketsSelection: function updateTicketsSelection(ticket) {
        if (!ticket.get('isChecked')) {
          this.data.tickets.pushObject(ticket);
          ticket.set('isChecked', true);

          if (this.data.tickets.length === this.eventTickets.length) {
            this.set('allTicketTypesChecked', true);
          }
        } else {
          this.data.tickets.removeObject(ticket);
          ticket.set('isChecked', false);
          this.set('allTicketTypesChecked', false);
        }
      },
      submit: function submit(data) {
        var _this2 = this;

        this.onValid(function () {
          _this2.sendAction('save', data);
        });
      },
      copiedText: function copiedText() {
        var _this3 = this;

        this.set('isLinkSuccess', true);
        Ember.run.later(this, function () {
          _this3.set('isLinkSuccess', false);
        }, 5000);
      },
      onChange: function onChange() {
        this.onValid(function () {});
      }
    }
  });

  _exports.default = _default;
});
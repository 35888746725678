define("open-event-frontend/components/widgets/forms/rich-text-editor", ["exports", "jquery", "ember-uuid", "open-event-frontend/utils/testing"], function (_exports, _jquery, _emberUuid, _testing) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    editor: null,
    // Ensure any changes to the parser rules are set in the sanitizer @ services/sanitizer.js
    standardParserRules: {
      tags: {
        'p': 1,
        'b': {
          'rename_tag': 'strong'
        },
        'strong': 1,
        'i': {
          'rename_tag': 'em'
        },
        'em': 1,
        'u': 1,
        'ol': 1,
        'li': 1,
        'ul': 1,
        'br': 1,
        'a': {
          'check_attributes': {
            'href': 'url'
          },
          'set_attributes': {
            'rel': 'nofollow',
            'target': '_blank'
          }
        }
      }
    },
    valueObserver: Ember.observer('value', function () {
      if (this.editor && this.editor.getValue() !== this.value) {
        this.editor.setValue(this.value);
      }
    }),
    textareaIdGenerated: Ember.computed('textareaId', function () {
      return this.textareaId ? this.textareaId : (0, _emberUuid.v4)();
    }),
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      this.set('_value', this.value);
      (0, _jquery.default)('.button', this.element).popup({
        inline: true,
        variation: 'tiny'
      }); // Don't initialize wysihtml5 when app is in testing mode

      if (!_testing.isTesting) {
        this.editor = new wysihtml5.Editor((0, _jquery.default)("#".concat(this.textareaIdGenerated), this.element)[0], {
          toolbar: (0, _jquery.default)("#".concat(this.textareaIdGenerated, "-toolbar"), this.element)[0],
          parserRules: this.standardParserRules
        });

        var updateValue = function updateValue() {
          Ember.run.debounce(_this, function () {
            var value = _this.editor.getValue();

            _this.setProperties({
              _value: value,
              value: value
            });
          }, 200);
        };

        this.editor.on('interaction', updateValue);
        this.editor.on('aftercommand:composer', updateValue);
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      if (this.editor) {
        this.editor.destroy();
      }

      (0, _jquery.default)('.button', this.element).popup('destroy');
    }
  });

  _exports.default = _default;
});
define("open-event-frontend/templates/components/ui-table/cell/cell-roles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "25TPmiO4",
    "block": "{\"symbols\":[\"role\",\"role\",\"role\",\"role\",\"role\"],\"statements\":[[10,\"div\"],[14,0,\"ui ordered list\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"item\"],[12],[1,[32,0,[\"record\",\"email\"]]],[2,\" (\"],[1,[30,[36,0],[\"Owner\"],null]],[2,\")\"],[13],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,0,[\"extraRecords\",\"organizers\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"item\"],[12],[1,[32,5,[\"email\"]]],[2,\" (\"],[1,[30,[36,0],[\"Organizer\"],null]],[2,\")\"],[13],[2,\"\\n\"]],\"parameters\":[5]}]]],[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,0,[\"extraRecords\",\"coorganizers\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"item\"],[12],[1,[32,4,[\"email\"]]],[2,\" (\"],[1,[30,[36,0],[\"Co-organizers\"],null]],[2,\")\"],[13],[2,\"\\n\"]],\"parameters\":[4]}]]],[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,0,[\"extraRecords\",\"trackOrganizers\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"item\"],[12],[1,[32,3,[\"email\"]]],[2,\" (\"],[1,[30,[36,0],[\"Track-organizers\"],null]],[2,\")\"],[13],[2,\"\\n\"]],\"parameters\":[3]}]]],[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,0,[\"extraRecords\",\"registrars\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"item\"],[12],[1,[32,2,[\"email\"]]],[2,\" (\"],[1,[30,[36,0],[\"Registrars\"],null]],[2,\")\"],[13],[2,\"\\n\"]],\"parameters\":[2]}]]],[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,0,[\"extraRecords\",\"moderators\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"item\"],[12],[1,[32,1,[\"email\"]]],[2,\" (\"],[1,[30,[36,0],[\"Moderators\"],null]],[2,\")\"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/ui-table/cell/cell-roles.hbs"
    }
  });

  _exports.default = _default;
});
define("open-event-frontend/models/admin-sales-fee", ["exports", "ember-data/attr", "open-event-frontend/models/base"], function (_exports, _attr, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    name: (0, _attr.default)('string'),
    paymentCurrency: (0, _attr.default)('string'),
    feePercentage: (0, _attr.default)('number'),
    maximumFee: (0, _attr.default)('number'),
    revenue: (0, _attr.default)('number'),
    ticketCount: (0, _attr.default)('number'),
    eventDate: (0, _attr.default)('moment')
  });

  _exports.default = _default;
});
define("open-event-frontend/components/account/password-section", ["exports", "open-event-frontend/mixins/form"], function (_exports, _form) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_form.default, {
    getValidationRules: function getValidationRules() {
      return {
        inline: true,
        delay: false,
        on: 'blur',
        fields: {
          currentPassword: {
            identifier: 'password_current',
            rules: [{
              type: 'empty',
              prompt: this.l10n.t('Please enter the current password')
            }]
          },
          newPassword: {
            identifier: 'password_new',
            rules: [{
              type: 'empty',
              prompt: this.l10n.t('Please enter a new password')
            }, {
              type: 'minLength[8]',
              prompt: this.l10n.t('Your password must have at least {ruleValue} characters')
            }]
          },
          repeatPassword: {
            identifier: 'password_repeat',
            rules: [{
              type: 'match[password_new]',
              prompt: this.l10n.t('Passwords do not match')
            }]
          }
        }
      };
    },
    actions: {
      showPassword: function showPassword(fieldName) {
        this.toggleProperty("showPass".concat(fieldName));
      },
      submit: function submit() {
        var _this = this;

        this.onValid(function () {
          _this.sendAction('changePassword', {
            passwordCurrent: _this.passwordCurrent,
            passwordNew: _this.passwordNew
          });
        });
      }
    }
  });

  _exports.default = _default;
});
define("open-event-frontend/templates/orders/expired", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YO9BXYZv",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"ui one column container stackable doubling left grid\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"column\"],[12],[2,\"\\n      \"],[10,\"h2\"],[12],[1,[32,0,[\"model\",\"event\",\"name\"]]],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"column\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"ui small gray-text\"],[12],[2,\"\\n        \"],[1,[30,[36,0],[[32,0,[\"model\",\"event\",\"startsAt\"]],\"date-time-long\"],null]],[2,\" - \"],[1,[30,[36,0],[[32,0,[\"model\",\"event\",\"endsAt\"]],\"date-time-long\"],null]],[2,\"\\n        \"],[10,\"br\"],[12],[13],[2,\"\\n        \"],[1,[32,0,[\"model\",\"event\",\"locationName\"]]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"ten wide column\"],[12],[2,\"\\n      \"],[8,\"orders/order-summary\",[],[[\"@data\",\"@eventCurrency\"],[[32,0,[\"model\"]],[32,0,[\"model\",\"event\",\"paymentCurrency\"]]]],null],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"mobile hidden six wide column\"],[12],[2,\"\\n      \"],[8,\"orders/event-info\",[],[[\"@data\"],[[32,0,[\"model\"]]]],null],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"general-date\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/orders/expired.hbs"
    }
  });

  _exports.default = _default;
});
define("open-event-frontend/templates/events/view/tickets/add-order", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/qcPU712",
    "block": "{\"symbols\":[],\"statements\":[[10,\"h2\"],[14,0,\"ui header\"],[12],[1,[30,[36,0],[\"Add Order\"],null]],[13],[2,\"\\n\"],[8,\"public/ticket-list\",[],[[\"@eventCurrency\",\"@data\",\"@event\",\"@attendees\",\"@order\",\"@placeOrder\",\"@save\",\"@amountEditable\"],[[32,0,[\"model\",\"store\",\"paymentCurrency\"]],[32,0,[\"model\",\"tickets\"]],[32,0,[\"model\",\"order\",\"event\"]],[32,0,[\"model\",\"attendees\"]],[32,0,[\"model\",\"order\"]],[30,[36,1],[[32,0],\"placeOrder\"],null],\"save\",true]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"action\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/events/view/tickets/add-order.hbs"
    }
  });

  _exports.default = _default;
});
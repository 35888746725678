define("open-event-frontend/models/event-image-size", ["exports", "ember-data/attr", "open-event-frontend/models/base", "open-event-frontend/mixins/custom-primary-key"], function (_exports, _attr, _base, _customPrimaryKey) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend(_customPrimaryKey.default, {
    fullAspect: (0, _attr.default)('boolean'),
    thumbnailHeight: (0, _attr.default)('number'),
    thumbnailAspect: (0, _attr.default)('boolean'),
    iconHeight: (0, _attr.default)('number'),
    iconAspect: (0, _attr.default)('boolean'),
    type: (0, _attr.default)('string'),
    iconWidth: (0, _attr.default)('number'),
    iconQuality: (0, _attr.default)('number'),
    logoWidth: (0, _attr.default)('number'),
    fullWidth: (0, _attr.default)('number'),
    thumbnailWidth: (0, _attr.default)('number'),
    thumbnailQuality: (0, _attr.default)('number'),
    fullHeight: (0, _attr.default)('number'),
    logoHeight: (0, _attr.default)('number'),
    fullQuality: (0, _attr.default)('number')
  });

  _exports.default = _default;
});
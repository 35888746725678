define("open-event-frontend/templates/components/create-session-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kIJN1u8A",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,3],[[32,0,[\"shouldShowMessage\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"ui icon info message eight wide column center aligned\"],[12],[2,\"\\n    \"],[10,\"i\"],[14,0,\"close icon\"],[15,\"onclick\",[30,[36,1],[[32,0],[30,[36,0],[[32,0,[\"isMessageVisible\"]]],null],false],null]],[14,\"role\",\"button\"],[12],[13],[2,\"\\n    \"],[10,\"i\"],[14,0,\"info icon\"],[12],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"content\"],[12],[2,\"\\n      \"],[1,[30,[36,2],[\"Your Speaker Details have been saved, you can now submit a proposal by clicking Submit proposal!\"],null]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"mut\",\"action\",\"t\",\"if\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/create-session-message.hbs"
    }
  });

  _exports.default = _default;
});
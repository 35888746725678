define("open-event-frontend/templates/components/ui-table/cell/admin/sales/cell-action", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+CAUOypH",
    "block": "{\"symbols\":[],\"statements\":[[2,\"  \"],[8,\"ui-popup\",[],[[\"@content\",\"@class\"],[[30,[36,0],[\"Resend invoice mail\"],null],\"ui icon button\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"i\"],[14,0,\"mail outline icon\"],[12],[13],[2,\"\\n  \"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/ui-table/cell/admin/sales/cell-action.hbs"
    }
  });

  _exports.default = _default;
});
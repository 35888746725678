define("open-event-frontend/templates/components/account/contact-info-section", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OR222aJN",
    "block": "{\"symbols\":[],\"statements\":[[11,\"form\"],[16,0,[31,[\"ui form \",[30,[36,0],[[32,0,[\"isLoading\"]],\"loading\"],null]]]],[24,\"novalidate\",\"\"],[4,[38,1],[[32,0],\"submit\"],[[\"on\"],[\"submit\"]]],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"field\"],[12],[2,\"\\n    \"],[10,\"label\"],[14,0,\"required\"],[12],[1,[30,[36,2],[\"Email\"],null]],[13],[2,\"\\n    \"],[8,\"input\",[],[[\"@type\",\"@name\",\"@value\"],[\"email\",\"email\",[32,0,[\"data\",\"email\"]]]],null],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"field\"],[12],[2,\"\\n    \"],[10,\"label\"],[14,0,\"required\"],[12],[1,[30,[36,2],[\"Phone\"],null]],[13],[2,\"\\n    \"],[8,\"input\",[],[[\"@type\",\"@name\",\"@value\"],[\"text\",\"phone\",[32,0,[\"data\",\"contact\"]]]],null],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"button\"],[14,0,\"ui teal button\"],[14,4,\"submit\"],[12],[1,[30,[36,2],[\"Save\"],null]],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"action\",\"t\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/account/contact-info-section.hbs"
    }
  });

  _exports.default = _default;
});
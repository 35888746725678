define("open-event-frontend/templates/components/tables/utilities/search-box", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0dOJ2/p7",
    "block": "{\"symbols\":[\"@size\"],\"statements\":[[10,\"div\"],[15,0,[31,[\"ui \",[30,[36,1],[[30,[36,0],[[32,1],\"large\"],null],\"\",\"small\"],null],\" icon input search-box\"]]],[12],[2,\"\\n  \"],[8,\"input\",[[24,\"placeholder\",\"Search ...\"]],[[\"@type\",\"@value\",\"@keyUp\"],[\"text\",[30,[36,2],[[32,0,[\"searchQuery\"]]],null],[30,[36,3],[[32,0],\"setSearchQuery\"],[[\"value\"],[\"target.value\"]]]]],null],[2,\"\\n  \"],[10,\"i\"],[14,0,\"search icon\"],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"eq\",\"if\",\"readonly\",\"action\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/tables/utilities/search-box.hbs"
    }
  });

  _exports.default = _default;
});
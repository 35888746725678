define("open-event-frontend/mixins/event-relation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A mixin that will replace event identifier with the event's original id while saving relationships
   */
  var _default = Ember.Mixin.create({
    serializeBelongsTo: function serializeBelongsTo(snapshot, json, relationship) {
      try {
        if (snapshot.belongsTo('event')) {
          snapshot.belongsTo('event').id = snapshot.belongsTo('event').attributes().originalId;
        }
      } catch (ignored) {
        /** ignore errors as some models won't be having event relationship **/
      }

      return this._super(snapshot, json, relationship);
    },
    serializeHasMany: function serializeHasMany(snapshot, json, relationship) {
      try {
        if (snapshot.hasMany('events') && snapshot.hasMany('events').length > 0) {
          for (var i = 0; i < snapshot.hasMany('events').length; i++) {
            snapshot.hasMany('events')[i].id = snapshot.hasMany('events')[i].attributes().originalId;
          }
        }
      } catch (ignored) {
        /** ignore errors as some models won't be having event relationship **/
      }

      return this._super(snapshot, json, relationship);
    }
  });

  _exports.default = _default;
});
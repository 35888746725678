define("open-event-frontend/models/email-notification", ["exports", "ember-data/attr", "open-event-frontend/models/base", "ember-data/relationships"], function (_exports, _attr, _base, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    /**
     * Attributes
     */
    nextEvent: (0, _attr.default)('boolean'),
    newPaper: (0, _attr.default)('boolean'),
    sessionAcceptReject: (0, _attr.default)('boolean'),
    sessionSchedule: (0, _attr.default)('boolean'),
    afterTicketPurchase: (0, _attr.default)('boolean'),

    /**
     * Relationships
     */
    user: (0, _relationships.belongsTo)('user'),
    event: (0, _relationships.belongsTo)('event')
  });

  _exports.default = _default;
});
define("open-event-frontend/templates/events/view/videoroom/list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QrMTkdit",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"ui warning message\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"header\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"This feature is still in alpha stage\"],null]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[1,[30,[36,0],[\"Please use with care.\"],null]],[2,\" \"],[1,[30,[36,0],[\"Thank You.\"],null]],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"ui grid\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"right aligned column\"],[12],[2,\"\\n      \"],[11,\"button\"],[24,0,\"ui blue button\"],[4,[38,2],[[32,0],[30,[36,1],[[32,0,[\"isRoomModalOpen\"]]],null],true],null],[12],[2,\"\\n        \"],[1,[30,[36,0],[\"Add Room\"],null]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"sixteen wide column\"],[12],[2,\"\\n  \"],[8,\"tables/default\",[],[[\"@columns\",\"@rows\",\"@currentPage\",\"@pageSize\",\"@searchQuery\",\"@sortBy\",\"@sortDir\",\"@metaData\",\"@filterOptions\",\"@widthConstraint\",\"@resizeMode\",\"@fillMode\"],[[32,0,[\"columns\"]],[32,0,[\"model\",\"rooms\",\"data\"]],[32,0,[\"page\"]],[32,0,[\"per_page\"]],[32,0,[\"search\"]],[32,0,[\"sort_by\"]],[32,0,[\"sort_dir\"]],[32,0,[\"model\",\"rooms\",\"meta\"]],[32,0,[\"filterOptions\"]],\"eq-container\",\"fluid\",\"equal-column\"]],null],[2,\"\\n\"],[13],[2,\"\\n\"],[8,\"modals/add-room-modal\",[],[[\"@event\",\"@isOpen\",\"@onAdded\"],[[32,0,[\"model\",\"event\"]],[32,0,[\"isRoomModalOpen\"]],[30,[36,2],[[32,0],[32,0,[\"refreshModel\"]]],null]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"mut\",\"action\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/events/view/videoroom/list.hbs"
    }
  });

  _exports.default = _default;
});
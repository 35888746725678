define("open-event-frontend/utils/file", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isFileValid = _exports.humanReadableBytes = void 0;

  var humanReadableBytes = function humanReadableBytes(sizeInKb) {
    var absolute = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
    var si = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
    var thresh = si ? 1000 : 1024;
    var bytes = sizeInKb * thresh;

    if (Math.abs(bytes) < thresh) {
      return "".concat(bytes, " B");
    }

    var units = si ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    var u = -1;

    do {
      bytes /= thresh;
      ++u;
    } while (Math.abs(bytes) >= thresh && u < units.length - 1);

    return "".concat(bytes.toFixed(absolute ? 0 : 1), " ").concat(units[u]);
  };

  _exports.humanReadableBytes = humanReadableBytes;

  var isFileValid = function isFileValid(file, maxSizeInMb) {
    var fileTypes = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
    return new Ember.RSVP.Promise(function (resolve, reject) {
      if (file.size > maxSizeInMb * 1024) {
        return reject("File size larger than ".concat(humanReadableBytes(maxSizeInMb)));
      } // If Uint8Array support is available, get the actual file type using file header. (Preferred way)


      if ('Uint8Array' in window) {
        var fileReader = new FileReader();

        fileReader.onloadend = function (e) {
          var arr = new Uint8Array(e.target.result).subarray(0, 4);
          var header = '';

          for (var i = 0; i < arr.length; i++) {
            header += arr[i].toString(16);
          }

          var type; // Magic number reference: from http://www.astro.keele.ac.uk/oldusers/rno/Computing/File_magic.html & http://en.wikipedia.org/wiki/List_of_file_signatures

          switch (header) {
            case '89504e47':
              type = 'image/png';
              break;

            case '47494638':
              type = 'image/gif';
              break;

            case 'ffd8ffe0':
            case 'ffd8ffe1':
            case 'ffd8ffe2':
            case 'ffd8ffdb':
            case 'ffd8ffee':
              type = 'image/jpeg';
              break;

            case '25504446':
              type = 'application/pdf';
              break;

            case 'd0cf11e0':
              type = 'application/vnd.ms-powerpoint';
              break;

            case '66747970':
              type = 'video/mp4';
              break;

            case '504b34':
              type = 'application/vnd.oasis.opendocument.presentation';
              break;

            default:
              type = 'unknown';
              break;
          }

          if (type !== 'unknown' && fileTypes.includes(file.type)) {
            return resolve();
          } else {
            return reject('File type not supported.');
          }
        };

        fileReader.readAsArrayBuffer(file);
      } else {
        // If no support for Uint8Array, get the mime from the blob directly (Easily spoof-able)
        if (fileTypes.includes(file.type)) {
          return resolve();
        } else {
          return reject('File type not supported.');
        }
      }
    });
  };

  _exports.isFileValid = isFileValid;
});
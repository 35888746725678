define("open-event-frontend/utils/dictionary/date-time", ["exports", "moment", "moment-timezone"], function (_exports, _moment, _momentTimezone) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.FORM_DATE_TIME_FORMAT = _exports.FORM_TIME_FORMAT = _exports.FORM_DATE_FORMAT = _exports.timezones = void 0;

  var timezones = _moment.default.tz.names();

  _exports.timezones = timezones;
  var FORM_DATE_FORMAT = 'MM-DD-YYYY';
  _exports.FORM_DATE_FORMAT = FORM_DATE_FORMAT;
  var FORM_TIME_FORMAT = 'HH:mm';
  _exports.FORM_TIME_FORMAT = FORM_TIME_FORMAT;
  var FORM_DATE_TIME_FORMAT = "".concat(FORM_DATE_FORMAT, " ").concat(FORM_TIME_FORMAT);
  _exports.FORM_DATE_TIME_FORMAT = FORM_DATE_TIME_FORMAT;
});
define("open-event-frontend/templates/components/ui-table/cell/cell-sponsor-image", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LwrRCMCe",
    "block": "{\"symbols\":[],\"statements\":[[10,\"img\"],[14,0,\"ui tiny image\"],[15,\"src\",[31,[[30,[36,0],[[32,0,[\"record\"]],[32,0,[\"record\"]],\"/images/placeholders/Other.jpg\"],null]]]],[14,\"alt\",\"Event logo\"],[12],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/ui-table/cell/cell-sponsor-image.hbs"
    }
  });

  _exports.default = _default;
});
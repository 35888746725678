define("open-event-frontend/components/g-map-address-marker", ["exports", "ember-g-map/components/g-map-address-marker"], function (_exports, _gMapAddressMarker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _gMapAddressMarker.default;
    }
  });
});
define("open-event-frontend/components/widgets/forms/location-input", ["exports", "lodash-es"], function (_exports, _lodashEs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = Ember.Component.extend({
    address: {
      venue: '',
      line: '',
      city: '',
      state: '',
      zipCode: '',
      country: ''
    },
    combinedAddress: Ember.computed('address.{venue,line,city,state,zipCode,country}', function () {
      return (0, _lodashEs.values)(this.address).join(' ').trim();
    }),
    searchableAddress: Ember.computed('address.{city}', function () {
      return this.address.city;
    }),
    placeNameChanger: Ember.observer('combinedAddress', 'searchableAddress', function () {
      this.setProperties({
        'placeName': this.combinedAddress,
        'searchableName': this.searchableAddress
      });
    }),
    actions: {
      showAddressView: function showAddressView() {
        var _this = this;

        var show = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
        this.set('addressViewIsShown', show);

        if (!show) {
          (0, _lodashEs.keys)(this.address).forEach(function (key) {
            _this.set("address.".concat(key), '');
          });
          this.setProperties({
            zoom: 1,
            lat: 0,
            lng: 0
          });
        }
      },
      onLocationChangeHandler: function onLocationChangeHandler(lat, lng) {
        this.setProperties({
          zoom: 17,
          lat: lat,
          lng: lng
        });
      },
      placeChanged: function placeChanged(place) {
        var _this2 = this;

        var addressComponents = place.address_components;
        addressComponents.forEach(function (component) {
          var _component$types = _slicedToArray(component.types, 1),
              type = _component$types[0];

          var value = component.long_name;

          switch (type) {
            case 'country':
              _this2.set('address.country', value);

              break;

            case 'administrative_area_level_1':
              _this2.set('address.state', value);

              break;

            case 'administrative_area_level_2':
              _this2.set('address.city', value);

              break;

            case 'route':
              _this2.set('address.line', "".concat(_this2.address.line).concat(value, " "));

              break;

            case 'sublocality_level_2':
              _this2.set('address.line', "".concat(_this2.address.line).concat(value, " "));

              break;

            case 'sublocality_level_1':
              _this2.set('address.line', "".concat(_this2.address.line).concat(value, " "));

              break;
          }
        });
        this.send('showAddressView');
      }
    }
  });

  _exports.default = _default;
});
define("open-event-frontend/templates/components/modals/room-info-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IboLrPNT",
    "block": "{\"symbols\":[],\"statements\":[[10,\"i\"],[14,0,\"black close icon\"],[12],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"header\"],[12],[2,\"\\n  \"],[1,[30,[36,0],[\"Additional Information\"],null]],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"content\"],[12],[2,\"\\n\"],[10,\"form\"],[14,0,\"ui form\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"field\"],[14,5,\"width:100%\"],[12],[2,\"\\n  \\t\"],[10,\"label\"],[12],[1,[30,[36,0],[\"Additional Information\"],null]],[13],[2,\"\\n      \"],[8,\"widgets/forms/rich-text-editor\",[],[[],[]],null],[2,\"\\n\\t\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"actions\"],[12],[2,\"\\n  \"],[11,\"button\"],[24,0,\"ui green button\"],[4,[38,2],[[32,0],[30,[36,1],[[32,0,[\"isOpen\"]]],null]],null],[12],[1,[30,[36,0],[\"Save\"],null]],[13],[2,\"\\n  \"],[11,\"button\"],[24,0,\"ui button\"],[4,[38,2],[[32,0],[30,[36,1],[[32,0,[\"isOpen\"]]],null]],null],[12],[1,[30,[36,0],[\"Cancel\"],null]],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"mut\",\"action\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/modals/room-info-modal.hbs"
    }
  });

  _exports.default = _default;
});
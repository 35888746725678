define("open-event-frontend/helpers/ticket-attendees", ["exports", "lodash-es"], function (_exports, _lodashEs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ticketAttendees = ticketAttendees;
  _exports.default = void 0;

  function ticketAttendees(params
  /* , hash*/
  ) {
    var allTicketAttendees = params[1].toArray();
    var orderAttendees = params[0].toArray();
    var commonAttendees = (0, _lodashEs.intersection)(orderAttendees, allTicketAttendees);
    return commonAttendees.length;
  }

  var _default = Ember.Helper.helper(ticketAttendees);

  _exports.default = _default;
});
define("open-event-frontend/templates/components/public/session-filter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JDrT9FJ7",
    "block": "{\"symbols\":[\"track\",\"id\"],\"statements\":[[10,\"div\"],[14,0,\"ui secondary menu\"],[12],[2,\"\\n  \"],[11,\"a\"],[24,6,\"#\"],[16,0,[31,[\"item \",[30,[36,3],[[32,0,[\"selectedTrackId\"]],\"active\"],null]]]],[4,[38,2],[[32,0],\"filter\"],null],[12],[2,\"\\n    \"],[1,[30,[36,4],[\"All\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\"],[6,[37,6],[[30,[36,5],[[32,0,[\"tracks\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[11,\"a\"],[24,6,\"#\"],[16,0,[31,[\"item \",[30,[36,1],[[30,[36,0],[[32,0,[\"selectedTrackId\"]],[32,1,[\"id\"]]],null],\"active\"],null]]]],[4,[38,2],[[32,0],\"filter\",[32,1,[\"id\"]]],null],[12],[2,\"\\n      \"],[1,[32,1,[\"name\"]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[1,2]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"eq\",\"if\",\"action\",\"unless\",\"t\",\"-each-in\",\"each\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/public/session-filter.hbs"
    }
  });

  _exports.default = _default;
});
define("open-event-frontend/templates/components/ui-table/cell/events/view/speakers/speaker-logo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2kTY/o2L",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"ui\"],[12],[2,\"\\n  \"],[10,\"img\"],[14,\"alt\",\"Speaker\"],[14,0,\"ui middle aligned tiny circular image\"],[15,\"src\",[31,[[30,[36,0],[[32,0,[\"record\"]],[32,0,[\"record\"]],[30,[36,0],[[32,0,[\"extraRecords\",\"photoUrl\"]],[32,0,[\"extraRecords\",\"photoUrl\"]],\"/images/placeholders/avatar.png\"],null]],null]]]],[12],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/ui-table/cell/events/view/speakers/speaker-logo.hbs"
    }
  });

  _exports.default = _default;
});
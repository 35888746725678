define("open-event-frontend/templates/components/modals/confirm-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3prGYJn7",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"header\"],[12],[2,\"\\n  \"],[1,[30,[36,1],[[32,0,[\"confirm\",\"promptText\"]],[32,0,[\"confirm\",\"promptText\"]],[30,[36,0],[\"Are you sure ?\"],null]],null]],[2,\"\\n\"],[13],[2,\"\\n\"],[6,[37,1],[[32,0,[\"confirm\",\"options\",\"extra\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"content\"],[12],[2,\"\\n    \"],[1,[32,0,[\"confirm\",\"options\",\"extra\"]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[10,\"div\"],[14,0,\"actions\"],[12],[2,\"\\n  \"],[11,\"button\"],[16,0,[31,[\"ui \",[30,[36,1],[[32,0,[\"confirm\",\"options\",\"denyText\"]],[32,0,[\"confirm\",\"options\",\"denyColor\"]],\"black\"],null],\" button\"]]],[24,4,\"button\"],[4,[38,2],[[32,0],\"deny\"],null],[12],[2,\"\\n    \"],[1,[30,[36,1],[[32,0,[\"confirm\",\"options\",\"denyText\"]],[32,0,[\"confirm\",\"options\",\"denyText\"]],[30,[36,0],[\"No\"],null]],null]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[11,\"button\"],[16,0,[31,[\"ui \",[30,[36,1],[[32,0,[\"confirm\",\"options\",\"denyText\"]],[32,0,[\"confirm\",\"options\",\"approveColor\"]],\"green\"],null],\" button\"]]],[24,4,\"button\"],[4,[38,2],[[32,0],\"confirm\"],null],[12],[2,\"\\n    \"],[1,[30,[36,1],[[32,0,[\"confirm\",\"options\",\"approveText\"]],[32,0,[\"confirm\",\"options\",\"approveText\"]],[30,[36,0],[\"Yes\"],null]],null]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"if\",\"action\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/modals/confirm-modal.hbs"
    }
  });

  _exports.default = _default;
});
define("open-event-frontend/services/confirm", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    isOpen: false,
    pendingPromise: {
      resolve: null,
      reject: null
    },
    prompt: function prompt() {
      var _this = this;

      var promptText = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'Are you sure ?';
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this.set('options', options);

        _this.set('promptText', promptText);

        _this.set('pendingPromise.resolve', resolve);

        _this.set('pendingPromise.reject', reject);

        _this.set('isOpen', true);
      });
    }
  });

  _exports.default = _default;
});
define("open-event-frontend/templates/components/tables/headers/sort", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Aqo8MwsX",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[15,0,[31,[[30,[36,1],[[32,0,[\"column\",\"color\"]],[30,[36,0],[\"ui inverted segment \",[32,0,[\"column\",\"color\"]]],null]],null]]]],[12],[2,\"\\n  \"],[18,1,null],[2,\"\\n\"],[6,[37,1],[[32,0,[\"column\",\"isSortable\"]]],null,[[\"default\"],[{\"statements\":[[2,\"     \"],[10,\"i\"],[15,0,[31,[[32,0,[\"sortIcon\"]],\" icon\"]]],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"concat\",\"if\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/tables/headers/sort.hbs"
    }
  });

  _exports.default = _default;
});
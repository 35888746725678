define("open-event-frontend/models/custom-placeholder", ["exports", "ember-data/attr", "open-event-frontend/models/base", "ember-data/relationships"], function (_exports, _attr, _base, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    origin: (0, _attr.default)('string'),
    thumbnailImageUrl: (0, _attr.default)('string'),
    name: (0, _attr.default)('string'),
    copyright: (0, _attr.default)('string'),
    originalImageUrl: (0, _attr.default)('string'),
    iconImageUrl: (0, _attr.default)('string'),
    largeImageUrl: (0, _attr.default)('string'),
    eventSubTopic: (0, _relationships.belongsTo)('event-sub-topic')
  });

  _exports.default = _default;
});
define("open-event-frontend/templates/public/cfs/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PyO5RwGY",
    "block": "{\"symbols\":[],\"statements\":[[8,\"public/call-for-speakers\",[],[[\"@data\"],[[32,0,[\"model\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/public/cfs/index.hbs"
    }
  });

  _exports.default = _default;
});
define("open-event-frontend/templates/components/ui-table/cell/events/view/tickets/orders/cell-amount", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JIkuIp7r",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"center aligned weight-400\"],[12],[2,\"\\n  \"],[8,\"currency-amount\",[],[[\"@currency\",\"@amount\"],[[32,0,[\"extraRecords\",\"event\",\"paymentCurrency\"]],[32,0,[\"record\"]]]],null],[2,\"\\n\"],[13],[2,\"\\n\"],[6,[37,0],[[32,0,[\"extraRecords\",\"discountCode\",\"code\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"ui mini yellow label\"],[12],[2,\"\\n    \"],[1,[32,0,[\"extraRecords\",\"discountCode\",\"code\"]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/ui-table/cell/events/view/tickets/orders/cell-amount.hbs"
    }
  });

  _exports.default = _default;
});
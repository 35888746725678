define("open-event-frontend/templates/components/events/view/overview/event-sponsors", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mssHZHql",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"content d-flex\"],[14,5,\"align-items: center;\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"header\"],[12],[1,[30,[36,0],[\"Sponsors\"],null]],[13],[2,\"\\n  \"],[8,\"link-to\",[[24,0,\"ui right floated blue button item ml-auto\"]],[[\"@route\",\"@tagName\"],[\"events.view.edit.sponsors\",\"button\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[30,[36,0],[\"Edit\"],null]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"content\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,5,\"width: 95%;margin-left:2.5%\"],[12],[2,\"\\n    \"],[8,\"tables/default\",[],[[\"@columns\",\"@rows\",\"@currentPage\",\"@pageSize\",\"@searchQuery\",\"@hideSearchBox\",\"@sortBy\",\"@sortDir\",\"@metaData\",\"@filterOptions\",\"@widthConstraint\",\"@resizeMode\",\"@fillMode\"],[[32,0,[\"columns\"]],[32,0,[\"data\",\"data\"]],[32,0,[\"page\"]],[32,0,[\"per_page\"]],[32,0,[\"search\"]],true,[32,0,[\"sort_by\"]],[32,0,[\"sort_dir\"]],[32,0,[\"data\",\"meta\"]],[32,0,[\"filterOptions\"]],\"eq-container\",\"fluid\",\"equal-column\"]],null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/events/view/overview/event-sponsors.hbs"
    }
  });

  _exports.default = _default;
});
define("open-event-frontend/templates/components/forms/admin/settings/system/mail-settings/test-email-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "grAjM9EI",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"ui form\"],[12],[2,\"\\n  \"],[8,\"ui-accordion\",[],[[\"@class\"],[\"field\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"title\"],[12],[2,\"\\n      \"],[10,\"i\"],[14,0,\"icon dropdown\"],[12],[13],[2,\"\\n      \"],[1,[30,[36,0],[\"Send Test Email\"],null]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"content ui grid\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"four wide computer four wide tablet sixteen wide mobile column \"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"ui action input\"],[12],[2,\"\\n          \"],[8,\"input\",[[16,\"placeholder\",[30,[36,0],[\"Recipient E-mail\"],null]]],[[\"@type\",\"@name\",\"@value\"],[\"text\",\"test_email\",[32,0,[\"recipientEmail\"]]]],null],[2,\"\\n          \"],[11,\"div\"],[24,0,\"ui secondary button\"],[24,\"role\",\"button\"],[4,[38,1],[[32,0],\"sendTestMail\"],null],[12],[2,\"\\n            \"],[1,[30,[36,0],[\"Test\"],null]],[2,\"\\n          \"],[13],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"action\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/forms/admin/settings/system/mail-settings/test-email-form.hbs"
    }
  });

  _exports.default = _default;
});
define("open-event-frontend/transforms/moment", ["exports", "ember-data/transform", "moment"], function (_exports, _transform, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _transform.default.extend({
    deserialize: function deserialize(serialized) {
      if (!serialized) {
        return null;
      }

      return (0, _moment.default)(serialized);
    },
    serialize: function serialize(deserialized) {
      if (!deserialized) {
        return null;
      }

      return (0, _moment.default)(deserialized).toISOString();
    }
  });

  _exports.default = _default;
});
define("open-event-frontend/models/admin-statistics-mail", ["exports", "ember-data/attr", "open-event-frontend/models/base"], function (_exports, _attr, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    oneDay: (0, _attr.default)('number'),
    threeDays: (0, _attr.default)('number'),
    sevenDays: (0, _attr.default)('number'),
    thirtyDays: (0, _attr.default)('number')
  });

  _exports.default = _default;
});
define("open-event-frontend/models/ticket", ["exports", "ember-data/attr", "open-event-frontend/models/base", "moment", "open-event-frontend/utils/computed-helpers", "ember-data/relationships"], function (_exports, _attr, _base, _moment, _computedHelpers, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    /**
     * Attributes
     */
    name: (0, _attr.default)('string'),
    type: (0, _attr.default)('string'),
    price: (0, _attr.default)('number'),
    quantity: (0, _attr.default)('number'),
    description: (0, _attr.default)('string'),
    isDescriptionVisible: (0, _attr.default)('boolean', {
      defaultValue: true
    }),
    isHidden: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    salesStartsAt: (0, _attr.default)('moment', {
      defaultValue: function defaultValue() {
        return (0, _moment.default)().startOf('day');
      }
    }),
    salesEndsAt: (0, _attr.default)('moment', {
      defaultValue: function defaultValue() {
        return (0, _moment.default)().add(10, 'days').startOf('day');
      }
    }),
    minOrder: (0, _attr.default)('number', {
      defaultValue: 1
    }),
    maxOrder: (0, _attr.default)('number', {
      defaultValue: 10
    }),
    minPrice: (0, _attr.default)('number', {
      defaultValue: 1
    }),
    maxPrice: (0, _attr.default)('number'),
    isFeeAbsorbed: (0, _attr.default)('boolean', {
      defaultValue: true
    }),
    position: (0, _attr.default)('number'),
    hasOrders: false,

    /**
     * Relationships
     */
    event: (0, _relationships.belongsTo)('event'),
    orders: (0, _relationships.hasMany)('order'),
    attendees: (0, _relationships.hasMany)('attendee'),
    orderStatistics: (0, _relationships.belongsTo)('order-statistics-ticket'),

    /**
     * Computed properties
     */
    salesStartAtDate: _computedHelpers.computedDateTimeSplit.bind(void 0)('salesStartsAt', 'date', 'salesEndsAt'),
    salesStartAtTime: _computedHelpers.computedDateTimeSplit.bind(void 0)('salesStartsAt', 'time', 'salesEndsAt'),
    salesEndsAtDate: _computedHelpers.computedDateTimeSplit.bind(void 0)('salesEndsAt', 'date'),
    salesEndsAtTime: _computedHelpers.computedDateTimeSplit.bind(void 0)('salesEndsAt', 'time'),
    itemTotal: Ember.computed('price', 'quantity', function () {
      return this.price * this.quantity;
    }),

    /**
     * This attribute computes total ticket price payable after inclusion
     * of additional taxes on the base ticket price
     */
    ticketPriceWithTax: Ember.computed('price', 'event.tax.isTaxIncludedInPrice', 'event.tax.rate', function () {
      var taxType = this.event.get('tax.isTaxIncludedInPrice');

      if (!taxType) {
        return ((1 + this.event.get('tax.rate') / 100) * this.price).toFixed(2);
      }

      return this.price;
    }),

    /**
     * This attribute computes value added tax amount in the cases
     * when tax amount is included in ticket price, otherwise return
     * 0
     */
    includedTaxAmount: Ember.computed('event.tax.isTaxIncludedInPrice', 'event.tax.rate', function () {
      var taxType = this.event.get('tax.isTaxIncludedInPrice');

      if (taxType) {
        var taxRate = this.event.get('tax.rate');
        return (taxRate * this.price / (100 + taxRate)).toFixed(2);
      }

      return 0;
    })
  });

  _exports.default = _default;
});
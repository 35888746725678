define("open-event-frontend/templates/components/widgets/steps-indicator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MpjOT1s7",
    "block": "{\"symbols\":[\"step\",\"index\"],\"statements\":[[10,\"div\"],[15,0,[31,[\"ui \",[30,[36,4],[[32,0,[\"processedSteps\",\"length\"]]],null],\" steps\"]]],[12],[2,\"\\n\"],[6,[37,6],[[30,[36,5],[[30,[36,5],[[32,0,[\"processedSteps\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"a\"],[15,0,[31,[[30,[36,0],[[30,[36,1],[[32,0,[\"currentIndex\"]],[32,2]],null],\"active\"],null],\" \",[30,[36,0],[[32,1,[\"isDisabled\"]],\"disabled\"],null],\" \",[30,[36,0],[[32,1,[\"isCompleted\"]],\"completed\"],null],\" step\"]]],[15,6,[31,[[30,[36,3],[[32,0,[\"disableLinks\"]],[30,[36,2],[[32,1,[\"route\"]]],null]],null]]]],[12],[2,\"\\n      \"],[10,\"i\"],[15,0,[31,[[32,1,[\"icon\"]]]]],[12],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"content\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"title\"],[12],[1,[32,1,[\"title\"]]],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"description\"],[12],[1,[32,1,[\"description\"]]],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[1,2]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"eq\",\"href-to\",\"unless\",\"ui-grid-number\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/widgets/steps-indicator.hbs"
    }
  });

  _exports.default = _default;
});
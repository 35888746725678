define("open-event-frontend/templates/admin/settings/billing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Y4ZqnG/C",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[15,0,[31,[\"ui basic \",[30,[36,0],[[32,0,[\"isLoading\"]],\"loading\"],null],\" segment\"]]],[12],[2,\"\\n  \"],[8,\"forms/admin/settings/billing\",[],[[\"@timezones\",\"@countries\",\"@model\",\"@save\"],[[32,0,[\"timezones\"]],[32,0,[\"countries\"]],[32,0,[\"model\"]],[30,[36,1],[[32,0],\"updateInvoiceModel\"],null]]],null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"action\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/admin/settings/billing.hbs"
    }
  });

  _exports.default = _default;
});
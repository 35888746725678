define("open-event-frontend/templates/admin/users/view/events", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XcvjSpM3",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"ui container\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"sixteen wide column\"],[12],[2,\"\\n      \"],[8,\"tabbed-navigation\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"link-to\",[[24,0,\"item\"]],[[\"@route\",\"@model\"],[\"admin.users.view.events.list\",\"live\"]],[[\"default\"],[{\"statements\":[[2,\"\\n          \"],[1,[30,[36,0],[\"Live\"],null]],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n        \"],[8,\"link-to\",[[24,0,\"item\"]],[[\"@route\",\"@model\"],[\"admin.users.view.events.list\",\"draft\"]],[[\"default\"],[{\"statements\":[[2,\"\\n          \"],[1,[30,[36,0],[\"Draft\"],null]],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n        \"],[8,\"link-to\",[[24,0,\"item\"]],[[\"@route\",\"@model\"],[\"admin.users.view.events.list\",\"past\"]],[[\"default\"],[{\"statements\":[[2,\"\\n          \"],[1,[30,[36,0],[\"Past\"],null]],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n        \"],[8,\"link-to\",[[24,0,\"item\"]],[[\"@route\",\"@model\"],[\"admin.users.view.events.list\",\"deleted\"]],[[\"default\"],[{\"statements\":[[2,\"\\n          \"],[1,[30,[36,0],[\"Deleted\"],null]],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[1,[30,[36,2],[[30,[36,1],null,null]],null]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/admin/users/view/events.hbs"
    }
  });

  _exports.default = _default;
});
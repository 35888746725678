define("open-event-frontend/templates/admin/reports/system-logs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "a44TNZmI",
    "block": "{\"symbols\":[],\"statements\":[[8,\"tabbed-navigation\",[],[[\"@isNonPointing\",\"@isTabbed\"],[true,true]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"link-to\",[[24,0,\"item\"]],[[\"@route\"],[\"admin.reports.system-logs.activity-logs\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[30,[36,0],[\"Activity\"],null]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[8,\"link-to\",[[24,0,\"item\"]],[[\"@route\"],[\"admin.reports.system-logs.mail-logs\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[30,[36,0],[\"Mails\"],null]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[8,\"link-to\",[[24,0,\"item\"]],[[\"@route\"],[\"admin.reports.system-logs.notification-logs\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[30,[36,0],[\"Notifications\"],null]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[10,\"div\"],[14,0,\"ui basic segment\"],[12],[2,\"\\n  \"],[1,[30,[36,2],[[30,[36,1],null,null]],null]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/admin/reports/system-logs.hbs"
    }
  });

  _exports.default = _default;
});
define("open-event-frontend/serializers/event-statistics-general", ["exports", "open-event-frontend/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload) {
      payload.data.attributes.speakersAccepted = payload.data.attributes.speakers.accepted;
      payload.data.attributes.speakersConfirmed = payload.data.attributes.speakers.confirmed;
      payload.data.attributes.speakersPending = payload.data.attributes.speakers.pending;
      payload.data.attributes.speakersRejected = payload.data.attributes.speakers.rejected;
      payload.data.attributes.speakersWithdrawn = payload.data.attributes.speakers.withdrawn;
      payload.data.attributes.speakersCanceled = payload.data.attributes.speakers.canceled;
      payload.data.attributes.speakersTotal = payload.data.attributes.speakers.total;
      return this._super.apply(this, arguments);
    },
    attrs: {
      speakersAccepted: 'speakersAccepted',
      speakersConfirmed: 'speakersConfirmed',
      speakersPending: 'speakersPending',
      speakersRejected: 'speakersRejected',
      speakersWithdrawn: 'speakersWithdrawn',
      speakersCanceled: 'speakersCanceled',
      speakers: 'speakersTotal'
    }
  });

  _exports.default = _default;
});
define("open-event-frontend/templates/admin/users/view/account/applications", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "b3e+PODM",
    "block": "{\"symbols\":[],\"statements\":[[8,\"account/application-section\",[],[[],[]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/admin/users/view/account/applications.hbs"
    }
  });

  _exports.default = _default;
});
define("open-event-frontend/components/modals/modal-base", ["exports", "jquery", "lodash-es", "semantic-ui-ember/components/ui-modal", "open-event-frontend/utils/testing"], function (_exports, _jquery, _lodashEs, _uiModal, _testing) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _uiModal.default.extend({
    tagName: 'div',
    classNames: ['centered-modal'],
    classNameBindings: ['isFullScreen:fullscreen', 'isSmall:small', 'isLarge:large'],
    openObserver: Ember.observer('isOpen', function () {
      var $element = (0, _jquery.default)(this.element);

      if (this.isOpen) {
        var _this$onOpen;

        $element.modal(this.defaultOptions).modal('show');
        (_this$onOpen = this.onOpen) === null || _this$onOpen === void 0 ? void 0 : _this$onOpen.call(this);
      } else {
        var _this$onClose;

        this.close();
        $element.modal('hide');
        (_this$onClose = this.onClose) === null || _this$onClose === void 0 ? void 0 : _this$onClose.call(this);
      }
    }),
    close: function close() {
      this.set('isOpen', false);
    },
    open: function open() {
      this.set('isOpen', true);
    },
    actions: {
      close: function close() {
        this.close();
      }
    },
    didRender: function didRender() {
      this._super.apply(this, arguments);

      try {
        (0, _jquery.default)(this.element).modal('refresh');
      } catch (ignored) {
        /* ignored exception */
        console.warn('Error refreshing modal', ignored);
      }
    },
    willInitSemantic: function willInitSemantic(settings) {
      var _this = this;

      this._super.apply(this, arguments);

      this.defaultOptions = {
        centered: false,
        duration: _testing.isTesting ? 0 : 200,
        dimmerSettings: {
          dimmerName: "".concat(this.elementId, "-modal-dimmer"),
          variation: 'inverted'
        },
        onHide: function onHide() {
          _this.set('isOpen', false);

          if (_this.onHide) {
            _this.onHide();
          }
        },
        onDeny: function onDeny() {
          if (_this.onDeny) {
            _this.onDeny();
          }

          return true;
        },
        onApprove: function onApprove() {
          if (_this.onApprove) {
            _this.onApprove();
          }

          return true;
        },
        onVisible: function onVisible() {
          _this.set('isOpen', true);

          var $element = (0, _jquery.default)(_this.element);
          $element.modal('refresh');
          $element.find('[data-content]').popup({
            inline: true
          });

          if (_this.onVisible) {
            _this.onVisible();
          }
        }
      };
      var options = this.options ? (0, _lodashEs.merge)(this.defaultOptions, this.options) : this.defaultOptions;
      Ember.assign(settings, options);
    },
    didInitSemantic: function didInitSemantic() {
      if (this.isOpen) {
        (0, _jquery.default)(this.element).modal(this.defaultOptions).modal('show');
      }
    }
  });

  _exports.default = _default;
});
define("open-event-frontend/templates/account/billing/invoices/list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "w6P3o7yz",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"sixteen wide column\"],[12],[2,\"\\n  \"],[8,\"tables/default\",[],[[\"@columns\",\"@rows\",\"@currentPage\",\"@pageSize\",\"@searchQuery\",\"@sortBy\",\"@sortDir\",\"@metaData\",\"@filterOptions\",\"@widthConstraint\",\"@resizeMode\",\"@fillMode\"],[[32,0,[\"columns\"]],[32,0,[\"model\",\"eventInvoices\",\"data\"]],[32,0,[\"page\"]],[32,0,[\"per_page\"]],[32,0,[\"search\"]],[32,0,[\"sort_by\"]],[32,0,[\"sort_dir\"]],[32,0,[\"model\",\"eventInvoices\",\"meta\"]],[32,0,[\"filterOptions\"]],\"eq-container\",\"fluid\",\"equal-column\"]],null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/account/billing/invoices/list.hbs"
    }
  });

  _exports.default = _default;
});
define("open-event-frontend/models/event-invoice", ["exports", "ember-data/attr", "open-event-frontend/models/base", "ember-data/relationships"], function (_exports, _attr, _base, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    /** attributes
     *
     */
    status: (0, _attr.default)('string'),
    identifier: (0, _attr.default)('string'),
    paypalToken: (0, _attr.default)('string'),
    transactionId: (0, _attr.default)('string'),
    brand: (0, _attr.default)('string'),
    createdAt: (0, _attr.default)('moment'),
    issuedAt: (0, _attr.default)('moment'),
    dueAt: (0, _attr.default)('moment'),
    paymentMode: (0, _attr.default)('string'),
    stripeToken: (0, _attr.default)('string'),
    last4: (0, _attr.default)('string'),
    expMonth: (0, _attr.default)('number'),
    expYear: (0, _attr.default)('number'),
    amount: (0, _attr.default)('number'),
    completedAt: (0, _attr.default)('moment'),
    invoicePdfUrl: (0, _attr.default)('string'),

    /** relationships
     *
     */
    user: (0, _relationships.belongsTo)('user'),
    event: (0, _relationships.belongsTo)('event')
  });

  _exports.default = _default;
});
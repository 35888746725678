define("open-event-frontend/templates/admin/messages", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "96WYDOf7",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"ui grid stackable\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n    \"],[1,[30,[36,1],[[30,[36,0],null,null]],null]],[2,\"\\n    \"],[10,\"div\"],[15,0,[31,[\"sixteen wide \",[30,[36,2],[[32,0,[\"device\",\"isMobile\"]],\"center aligned\"],null],\" column\"]]],[12],[2,\"\\n      \"],[11,\"button\"],[16,0,[31,[\"ui blue button left floated \",[30,[36,2],[[32,0,[\"isLoading\"]],\"loading\"],null]]]],[4,[38,3],[[32,0],\"save\"],null],[12],[1,[30,[36,4],[\"Save\"],null]],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"-outlet\",\"component\",\"if\",\"action\",\"t\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/admin/messages.hbs"
    }
  });

  _exports.default = _default;
});
define("open-event-frontend/templates/head", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9sTGyNg0",
    "block": "{\"symbols\":[],\"statements\":[[10,\"title\"],[12],[1,[32,0,[\"model\",\"title\"]]],[13],[2,\"\\n\"],[10,\"meta\"],[14,3,\"title\"],[15,\"content\",[32,0,[\"model\",\"title\"]]],[12],[13],[2,\"\\n\"],[6,[37,0],[[32,0,[\"model\",\"description\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"meta\"],[14,3,\"description\"],[15,\"content\",[32,0,[\"model\",\"description\"]]],[12],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"meta\"],[14,3,\"description\"],[14,\"content\",\"Groovy Events - a home where event organisers can create and manage their event.\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[10,\"meta\"],[14,\"property\",\"og:title\"],[15,\"content\",[32,0,[\"model\",\"title\"]]],[12],[13],[2,\"\\n\"],[6,[37,0],[[32,0,[\"model\",\"description\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"meta\"],[14,\"property\",\"og:description\"],[15,\"content\",[32,0,[\"model\",\"description\"]]],[12],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"meta\"],[14,\"property\",\"og:description\"],[14,\"content\",\"Groovy Events - a home where event organisers can create and manage their event.\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/head.hbs"
    }
  });

  _exports.default = _default;
});
define("open-event-frontend/helpers/order-color", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.orderColor = orderColor;
  _exports.default = void 0;

  function orderColor(params) {
    switch (params[0]) {
      case 'completed':
        return 'green';

      case 'placed':
        return 'blue';

      case 'initializing':
        return 'yellow';

      case 'pending':
        return 'orange';

      case 'expired':
        return 'red';

      default:
        return 'grey';
    }
  }

  var _default = Ember.Helper.helper(orderColor);

  _exports.default = _default;
});
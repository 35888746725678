define("open-event-frontend/templates/components/currency-amount", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MtmF+V6d",
    "block": "{\"symbols\":[\"@currency\",\"@amount\"],\"statements\":[[6,[37,2],[[30,[36,1],[[32,2],0],null]],null,[[\"default\"],[{\"statements\":[[1,[30,[36,0],[[32,1]],null]]],\"parameters\":[]}]]],[2,\" \"],[1,[30,[36,3],[[32,2]],null]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"currency-symbol\",\"gt\",\"if\",\"format-money\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/currency-amount.hbs"
    }
  });

  _exports.default = _default;
});
define("open-event-frontend/templates/components/ui-table/header-sorting-icons", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ds4oQyPb",
    "block": "{\"symbols\":[],\"statements\":[[10,\"i\"],[15,0,[31,[[30,[36,0],[[32,0,[\"column\",\"sortAsc\"]],\"caret up icon\"],null],\" \",[30,[36,0],[[32,0,[\"column\",\"sortDesc\"]],\"caret down icon\"],null]]]],[12],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/ui-table/header-sorting-icons.hbs"
    }
  });

  _exports.default = _default;
});
define("open-event-frontend/models/notification", ["exports", "ember-data/attr", "ember-data/relationships", "open-event-frontend/models/base"], function (_exports, _attr, _relationships, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    title: (0, _attr.default)('string'),
    message: (0, _attr.default)('string'),
    isRead: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    receivedAt: (0, _attr.default)('moment'),

    /*
    * Relationships
    */
    user: (0, _relationships.belongsTo)('user'),
    notificationActions: (0, _relationships.hasMany)('notification-action')
  });

  _exports.default = _default;
});
define("open-event-frontend/templates/components/widgets/twitter-timeline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RvWM+Xvm",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,2],[[32,0,[\"handle\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"a\"],[14,0,\"twitter-timeline\"],[15,6,[31,[[32,0,[\"normalizedUrl\"]]]]],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener nofollow\"],[14,\"data-height\",\"500\"],[12],[2,\"\\n    \"],[10,\"i\"],[14,0,\"twitter icon\"],[12],[13],[2,\"\\n    \"],[1,[30,[36,0],[\"Tweets by\"],null]],[2,\" @\"],[1,[34,1]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"script\"],[14,\"async\",\"\"],[14,\"src\",\"https://platform.twitter.com/widgets.js\"],[14,\"charset\",\"utf-8\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"handle\",\"if\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/widgets/twitter-timeline.hbs"
    }
  });

  _exports.default = _default;
});
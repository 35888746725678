define("open-event-frontend/templates/verify", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Wi2BxdOY",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,0],[[32,0,[\"success\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[15,0,[31,[\"ui \",[30,[36,0],[[32,0,[\"isLoading\"]],\"loading\"],null],\" icon info message eight wide column center aligned\"]]],[12],[2,\"\\n    \"],[10,\"p\"],[12],[2,\"Thank you, your email has been verified successfully! You now have full access to all features.\"],[6,[37,0],[[30,[36,1],[[32,0,[\"session\",\"isAuthenticated\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\" Please login to continue. \"]],\"parameters\":[]}]]],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"div\"],[15,0,[31,[\"ui \",[30,[36,0],[[32,0,[\"isLoading\"]],\"loading\"],null],\" icon error message eight wide column center aligned\"]]],[12],[2,\"\\n    \"],[10,\"p\"],[12],[2,\"Error: \"],[1,[32,0,[\"error\"]]],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"not\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/verify.hbs"
    }
  });

  _exports.default = _default;
});
define("open-event-frontend/components/forms/register-form", ["exports", "open-event-frontend/mixins/form"], function (_exports, _form) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_form.default, {
    email: '',
    password: '',
    isLoading: false,
    getValidationRules: function getValidationRules() {
      return {
        inline: true,
        delay: false,
        on: 'blur',
        fields: {
          email: {
            identifier: 'email',
            rules: [{
              type: 'email',
              prompt: this.l10n.t('Please enter a valid email address')
            }]
          },
          password: {
            identifier: 'password',
            rules: [{
              type: 'empty',
              prompt: this.l10n.t('Please enter a password')
            }, {
              type: 'minLength[8]',
              prompt: this.l10n.t('Your password must have at least {ruleValue} characters')
            }]
          },
          passwordRepeat: {
            identifier: 'password_repeat',
            rules: [{
              type: 'match[password]',
              prompt: this.l10n.t('Passwords do not match')
            }]
          }
        }
      };
    },
    didInsertElement: function didInsertElement() {
      if (this.inviteEmail) {
        this.data.set('email', this.inviteEmail);
      }
    },
    actions: {
      submit: function submit() {
        var _this = this;

        this.onValid(function () {
          _this.set('errorMessage', null);

          _this.set('isLoading', true);

          _this.sendAction('submit');
        });
      },
      showSignupPassword: function showSignupPassword() {
        this.toggleProperty('showSignupPass');
      },
      showConfirmPassword: function showConfirmPassword() {
        this.toggleProperty('showConfirmPass');
      }
    }
  });

  _exports.default = _default;
});
define("open-event-frontend/templates/components/widgets/forms/time-picker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wH2bTCtH",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,0],[[32,0,[\"icon\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"i\"],[14,0,\"time icon\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[10,\"div\"],[14,0,\"ui input\"],[12],[2,\"\\n  \"],[8,\"input\",[[16,\"placeholder\",[32,0,[\"placeholder\"]]]],[[\"@type\",\"@value\",\"@name\",\"@focus-out\"],[\"text\",[34,1],[34,2],[30,[36,3],[[32,0],\"onChange\"],null]]],null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"if\",\"value\",\"name\",\"action\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/widgets/forms/time-picker.hbs"
    }
  });

  _exports.default = _default;
});
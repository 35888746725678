define("open-event-frontend/utils/loader", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getScript = void 0;

  var getScript = function getScript(url) {
    return new Promise(function (resolve, reject) {
      var script = document.createElement('script');
      script.src = url;
      script.async = true;
      script.onerror = reject;

      script.onload = script.onreadystatechange = function () {
        var loadState = this.readyState;

        if (loadState && loadState !== 'loaded' && loadState !== 'complete') {
          return;
        }

        script.onload = script.onreadystatechange = null;
        resolve();
      };

      document.head.appendChild(script);
    });
  };

  _exports.getScript = getScript;
});
define("open-event-frontend/components/widgets/forms/places-autocomplete", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = Ember.TextField.extend({
    classNames: ['place-autocomplete--input'],
    types: 'geocode',
    restrictions: {},
    tabindex: 0,
    withGeoLocate: false,
    setValueWithProperty: 'formatted_address',
    // @see https://developers.google.com/maps/documentation/javascript/places-autocomplete#set_search_area
    geolocate: function geolocate() {
      var _this = this;

      if (this.fastboot.isFastBoot) {
        return;
      }

      var navigator = this.navigator || (window ? window.navigator : null);

      if (navigator && navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(function (position) {
          var google = _this.google || window.google;
          var geolocation = {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          };
          var circle = new google.maps.Circle({
            center: geolocation,
            radius: position.coords.accuracy
          });

          _this.autocomplete.setBounds(circle.getBounds());
        });
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      Ember.run.scheduleOnce('afterRender', this, 'setupComponent');
    },
    setupComponent: function setupComponent() {
      var _this2 = this;

      this.getAutocomplete();
      this.autocomplete.addListener('place_changed', function () {
        Ember.run(function () {
          _this2.placeChanged();
        });
      });

      if (this.withGeoLocate) {
        this.geolocate();
      }
    },
    willDestroy: function willDestroy() {
      if (Ember.isPresent(this.autocomplete)) {
        var google = this.google || (window ? window.google : null);

        if (google) {
          google.maps.event.clearInstanceListeners(this.autocomplete);
        }
      }
    },
    getAutocomplete: function getAutocomplete() {
      if (Ember.isEmpty(this.autocomplete)) {
        if (document && window) {
          var _$ = (0, _jquery.default)(this.element),
              _$2 = _slicedToArray(_$, 1),
              inputElement = _$2[0];

          var google = this.google || window.google;
          var options = {
            types: this._typesToArray()
          };

          if (Object.keys(this.restrictions).length > 0) {
            options.componentRestrictions = this.restrictions;
          }

          var autocomplete = new google.maps.places.Autocomplete(inputElement, options);
          this.set('autocomplete', autocomplete);
        }
      }
    },
    placeChanged: function placeChanged() {
      if (this.placeChangedCallback) {
        var place = this.autocomplete.getPlace();
        this.placeChangedCallback(place);
        this.set('value', place[this.setValueWithProperty]);
      }
    },
    _typesToArray: function _typesToArray() {
      if (this.types !== '') {
        return this.types.split(',');
      } else {
        return [];
      }
    },
    actions: {
      focusOut: function focusOut() {
        if (this.focusOutCallback) {
          this.focusOutCallback();
        }
      }
    }
  });

  _exports.default = _default;
});
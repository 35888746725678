define("open-event-frontend/models/notification-action", ["exports", "ember-data/attr", "open-event-frontend/models/base", "ember-data/relationships"], function (_exports, _attr, _base, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    subject: (0, _attr.default)('string'),
    actionType: (0, _attr.default)('string'),
    subjectId: (0, _attr.default)('string'),
    link: (0, _attr.default)('string'),
    notificationId: (0, _attr.default)('string'),
    notification: (0, _relationships.belongsTo)('notification'),

    /**
     * Computed properties
     */
    buttonTitle: Ember.computed('subject', 'actionType', function () {
      var action, buttonSubject;

      switch (this.actionType) {
        case 'download':
          action = 'Download';
          break;

        case 'submit':
          action = 'Submit';
          break;

        default:
          action = 'View';
      }

      switch (this.subject) {
        case 'event-export':
          buttonSubject = ' Event';
          break;

        case 'event':
          buttonSubject = ' Event';
          break;

        case 'invoice':
          buttonSubject = ' Invoice';
          break;

        case 'order':
          buttonSubject = ' Order';
          break;

        case 'tickets-pdf':
          buttonSubject = ' Tickets';
          break;

        case 'event-role':
          buttonSubject = ' Invitation Link';
          break;

        case 'session':
          buttonSubject = ' Session';
          break;

        case 'call-for-speakers':
          if (this.actionType === 'submit') {
            buttonSubject = ' Proposal';
          } else {
            buttonSubject = ' Call for Speakers';
          }

          break;

        default: // Nothing here.

      }

      return action + buttonSubject;
    }),

    /**
     * The route name to which the action button will direct the user to.
     */
    buttonRoute: Ember.computed('subject', function () {
      var routeName;

      switch (this.subject) {
        case 'event-export':
          routeName = 'events.view';
          break;

        case 'event':
          routeName = 'events.view';
          break;

        case 'invoice':
          routeName = 'orders.view';
          break;

        case 'order':
          routeName = 'orders.view';
          break;

        case 'session':
          routeName = 'my-sessions.view';
          break;

        default: // Nothing here.

      }

      return routeName;
    })
  });

  _exports.default = _default;
});
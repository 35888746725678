define("open-event-frontend/templates/public/speakers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QnJGtjRC",
    "block": "{\"symbols\":[\"speaker\"],\"statements\":[[10,\"div\"],[14,0,\"ui stackable grid container\"],[12],[2,\"\\n\"],[6,[37,1],[[30,[36,0],[[30,[36,0],[[32,0,[\"model\",\"speakers\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"five wide column speaker-column\"],[12],[2,\"\\n      \"],[8,\"public/speaker-item\",[],[[\"@speaker\",\"@timezone\"],[[32,1],[32,0,[\"model\",\"event\",\"timezone\"]]]],null],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"  \"],[10,\"div\"],[14,0,\"sixteen wide column\"],[12],[2,\"\\n    \"],[8,\"infinity-loader\",[],[[\"@infinityModel\"],[[32,0,[\"model\",\"speakers\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[10,\"div\"],[14,0,\"ui loading very padded basic segment\"],[12],[2,\"\\n      \"],[13],[2,\"\\n      \"],[1,[32,0,[\"infintyModel\",\"reachedInfinity\"]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/public/speakers.hbs"
    }
  });

  _exports.default = _default;
});
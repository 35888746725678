define("open-event-frontend/models/order", ["exports", "ember-data/attr", "open-event-frontend/models/base", "ember-data/relationships", "open-event-frontend/mixins/custom-primary-key"], function (_exports, _attr, _base, _relationships, _customPrimaryKey) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend(_customPrimaryKey.default, {
    /**
     * attributes
     */
    amount: (0, _attr.default)('number'),
    company: (0, _attr.default)('string'),
    taxBusinessInfo: (0, _attr.default)('string'),
    address: (0, _attr.default)('string'),
    city: (0, _attr.default)('string'),
    state: (0, _attr.default)('string'),
    country: (0, _attr.default)('string'),
    zipcode: (0, _attr.default)('string'),
    paymentMode: (0, _attr.default)('string', {
      defaultValue: 'free'
    }),
    status: (0, _attr.default)('string', {
      defaultValue: 'initializing'
    }),
    transactionId: (0, _attr.default)('string', {
      readOnly: true
    }),
    expMonth: (0, _attr.default)('string'),
    expYear: (0, _attr.default)('string'),
    identifier: (0, _attr.default)('string', {
      readOnly: true
    }),
    brand: (0, _attr.default)('string'),
    last4: (0, _attr.default)('string'),
    paidVia: (0, _attr.default)('string'),
    isBillingEnabled: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    createdAt: (0, _attr.default)('moment', {
      readOnly: true
    }),
    completedAt: (0, _attr.default)('moment', {
      readOnly: true
    }),
    discountCodeId: (0, _attr.default)('string', {
      readOnly: true
    }),
    ticketsPdfUrl: (0, _attr.default)('string'),

    /**
     * Relationships
     */
    user: (0, _relationships.belongsTo)('user'),
    event: (0, _relationships.belongsTo)('event'),
    discountCode: (0, _relationships.belongsTo)('discount-code'),
    accessCode: (0, _relationships.belongsTo)('access-code'),
    tickets: (0, _relationships.hasMany)('ticket', {
      readOnly: true
    }),
    attendees: (0, _relationships.hasMany)('attendee')
  });

  _exports.default = _default;
});
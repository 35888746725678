define("open-event-frontend/templates/components/ui-table/cell/admin/reports/system-logs/notification-logs/cell-for", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9d/h3tzm",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,2],[[30,[36,1],[[32,0,[\"record\",\"firstName\"]],[32,0,[\"record\",\"lastName\"]]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[1,[32,0,[\"record\",\"firstName\"]]],[2,\" \"],[1,[32,0,[\"record\",\"lastName\"]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[1,[30,[36,0],[\"No Name Provided\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[10,\"div\"],[14,0,\"muted text\"],[12],[1,[32,0,[\"record\",\"email\"]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"or\",\"if\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/ui-table/cell/admin/reports/system-logs/notification-logs/cell-for.hbs"
    }
  });

  _exports.default = _default;
});
define("open-event-frontend/templates/components/ui-table/cell/events/view/tickets/discount-codes/cell-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MuIlxfoR",
    "block": "{\"symbols\":[],\"statements\":[[10,\"span\"],[12],[2,\"\\n\"],[6,[37,1],[[32,0,[\"extraRecords\",\"isExpired\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"a\"],[14,6,\"#\"],[14,0,\"ui red label\"],[12],[1,[30,[36,0],[\"Expired\"],null]],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,1],[[32,0,[\"record\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[10,\"a\"],[14,6,\"#\"],[14,0,\"ui green label\"],[12],[1,[30,[36,0],[\"Active\"],null]],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[10,\"a\"],[14,6,\"#\"],[14,0,\"ui yellow label\"],[12],[1,[30,[36,0],[\"Inactive\"],null]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"if\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/ui-table/cell/events/view/tickets/discount-codes/cell-status.hbs"
    }
  });

  _exports.default = _default;
});
define("open-event-frontend/components/forms/events/view/edit-session", ["exports", "open-event-frontend/mixins/form"], function (_exports, _form) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_form.default, {
    actions: {
      submit: function submit() {
        var _this = this;

        this.onValid(function () {
          _this.sendAction('save');
        });
      }
    }
  });

  _exports.default = _default;
});
define("open-event-frontend/templates/loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jtCSnlcf",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"ui active centered inline loader\"],[12],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/loading.hbs"
    }
  });

  _exports.default = _default;
});
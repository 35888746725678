define("open-event-frontend/components/ui-table/cell/admin/sales/invoice-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <a href="{{href-to 'account.billing.invoices.list' 'all' (query-params user_id=@record.id)}}" target="_blank" rel="noopener nofollow" data-tooltip="View user's invoices">{{ @record.email }}</a>
  
  */
  {"id":"OAfjzriI","block":"{\"symbols\":[\"@record\"],\"statements\":[[10,\"a\"],[15,6,[31,[[30,[36,1],[\"account.billing.invoices.list\",\"all\",[30,[36,0],null,[[\"user_id\"],[[32,1,[\"id\"]]]]]],null]]]],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener nofollow\"],[14,\"data-tooltip\",\"View user's invoices\"],[12],[1,[32,1,[\"email\"]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"query-params\",\"href-to\"]}","meta":{"moduleName":"open-event-frontend/components/ui-table/cell/admin/sales/invoice-user.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});
define("open-event-frontend/templates/components/ui-table/cell/admin/users/cell-user-links", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EnSoOdqd",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"ui ordered list\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"item\"],[12],[2,\"\\n    \"],[8,\"link-to\",[],[[\"@route\",\"@models\"],[\"admin.users.view.sessions.list\",[30,[36,0],[[32,0,[\"record\"]],\"upcoming\"],null]]],[[\"default\"],[{\"statements\":[[2,\" \"],[1,[30,[36,1],[\"Sessions\"],null]],[2,\" \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"item\"],[12],[2,\"\\n    \"],[8,\"link-to\",[],[[\"@route\",\"@model\"],[\"admin.users.view.events\",[32,0,[\"record\"]]]],[[\"default\"],[{\"statements\":[[2,\" \"],[1,[30,[36,1],[\"Events\"],null]],[2,\" \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"item\"],[12],[2,\"\\n    \"],[8,\"link-to\",[],[[\"@route\",\"@model\"],[\"admin.users.view.tickets\",[32,0,[\"record\"]]]],[[\"default\"],[{\"statements\":[[2,\" \"],[1,[30,[36,1],[\"Tickets\"],null]],[2,\" \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"item\"],[12],[2,\"\\n    \"],[8,\"link-to\",[],[[\"@route\",\"@model\"],[\"admin.users.view.account.profile\",[32,0,[\"record\"]]]],[[\"default\"],[{\"statements\":[[2,\" \"],[1,[30,[36,1],[\"Accounts\"],null]],[2,\" \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"array\",\"t\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/ui-table/cell/admin/users/cell-user-links.hbs"
    }
  });

  _exports.default = _default;
});
define("open-event-frontend/helpers/payment-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.paymentIcon = paymentIcon;
  _exports.default = void 0;

  function paymentIcon(params) {
    switch (params[0]) {
      case 'Visa':
        return 'big cc visa icon';

      case 'MasterCard':
        return 'big cc mastercard icon';

      case 'American Express':
        return 'big cc amex icon';

      default:
        return false;
    }
  }

  var _default = Ember.Helper.helper(paymentIcon);

  _exports.default = _default;
});